<div
  class="header-wrapper header-subscribe items"
  *ngIf="headerView == 'subscribe' || headerView == 'login'"
  [ngStyle]="{ 'background-image': 'url(' + bgName + ')' }"
>
  <div class="icon">
    <app-select-country></app-select-country>
  </div>
  <div class="logo" [ngClass]="{ margin: arabicLang == false }">
    <div class="logoC">
      <a class="logoA" (click)="isAndroid()" href="/home/games/android">
        <div class="signUplogoImg"></div>
      </a>
    </div>
  </div>

 
</div>
<div *ngIf="customM">
  <div class="header-container " *ngIf="headerView == 'sharedHeader'">
    <div class="nav" *ngIf="customM">
      <app-side-nav *ngIf="isUserExist"></app-side-nav>
    </div>
    <div class="logo">
      <div>
        <a (click)="isAndroid()" routerLink="/home/games/android">
          <div class="navbarlogoImg"></div>
        </a>
      </div>
    </div>

    <div>
      <div class="icon">
        <div class="menu-button">
          <button (click)="searchShow()" *ngIf="customM">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="icon">
      <app-select-country></app-select-country>
    </div>
  </div>

  <div class="container-search" *ngIf="showSearch">
    <div class="search">
      <button (click)="searchNow()">
        <img
          src="../../../assets/images/common/search.png"
          width="23"
          height="23"
          alt=""
        />
      </button>
      <input
        (keyup.enter)="searchNow()"
        type="text"
        [(ngModel)]="inputSearch"
        placeholder="{{ 'SEARCH HERE' | translate }} ..."
      />
    </div>
  </div>
</div>
