import { CookieService } from 'ngx-cookie-service';
import { UserService } from './../user/user.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  constructor(private userService: UserService, private router: Router, private cookieService: CookieService ) { }
  canActivate() {
    if (!this.userService.getUserId() && !this.cookieService.check('userId')) {    
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }
}
