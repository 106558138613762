import { UserService } from './../../shared/services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../services/search/search.service';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchInput: string;
  games: any;
  notfound:Boolean = false;
  searchPage;
  constructor(
    private activeRoute: ActivatedRoute,
    private searchService: SearchService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.searchPage = window.location.pathname.split('/')[1] === 'search' ? true : false;
    if (localStorage.getItem('headerView') !== 'sharedHeader') {
      location.reload();
    }
    this.captureSearchGame();
  }
  /**
   * Gets search text from params
   */
  captureSearchGame() {
    this.activeRoute.params.subscribe(params => {
      this.spinnerService.show();
      this.searchGame(params['inputSearch']);
      this.searchInput = params['inputSearch'];
    });
  }
  /**
   * Gets searched game. 
   * @param searchInput 
   */
  searchGame(searchInput) {
    this.searchService.searchGames(searchInput).subscribe((res) => {
      this.games = res['search_result'];
      if (res) {
        this.spinnerService.hide();
        if (this.games.length > 0) {
          this.notfound = false;
        } else {
          this.notfound = true;
        }
        
      }
      setTimeout(() => {
        if (!this.games) {
          this.spinnerService.hide();
          this.notfound = true;
        }
      }, 3000);
    });
  }
  /**
   * Checks if the user is logged in and directs him accordingly. 
   * @param gameType 
   * @param gameId 
   */
  checkLogin(gameType, gameId) {
    if (this.userService.getUserId()) {
      this.router.navigateByUrl('game/' + gameType + '/' + gameId);
    } else {
      this.router.navigateByUrl('login');
    }
  }

}
