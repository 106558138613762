import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public navLink ;
  public hostn;
  

  public hostName: string = 'http://www.api.playit.mobi/api/v1/';
  public hostNamev2: string = 'http://www.api.playit.mobi/api/v2/';
  public baseURL: string = 'http://staging.playit.mobi/api/v2/';
  public testingHost = "http://testing.playit.mobi/api/v2/";
  public host = "http://testing.playit.mobi/api/playit/";

  constructor() {
    if(window.location.hostname === 'play.mtnsyr.com'){
      this.hostName = 'http://play.mtnsyr.com/syapi/api/v1/';
      this.hostNamev2 = 'http://play.mtnsyr.com/syapi/api/v2/';
      this.baseURL = 'http://play.mtnsyr.com/sysapi/api/v2/';
      this.testingHost = "http://play.mtnsyr.com/sytapi/api/v2/";
      this.host = "http://play.mtnsyr.com/sytapi/api/playit/";
    }
   }
  getHostName() {
    return this.hostName;
  }
  getBaseURL() {
    return this.baseURL;
  }
  getTestingHostName() {
    return this.testingHost;
  }
  getHost() {
    return this.host;
  }
  getHostv2() {
    return this.hostNamev2;
  }
}
