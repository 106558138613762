import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountryService } from './shared/services/country/country.service';
import { ThemesService } from './shared/services/themes/themes.service';
import { UserService } from './shared/services/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  headerView = '';
  navLink = '';
  hostName: string;

  constructor(
    private translateService: TranslateService,
    private countryService: CountryService,
    private userService: UserService,
    private themeService: ThemesService,
    private loadingSpinner: NgxSpinnerService,
  ) {

    this.navLink = window.location.href;
    this.hostName = window.location.hostname;
    if (this.navLink.includes('login')) {
      
      this.headerView = 'login';
    } else if (this.navLink.includes('subscribe')) {
      this.headerView = 'subscribe';
    } else {
      this.headerView = 'sharedHeader';
    }
    localStorage.setItem('headerView', this.headerView);
  }

  ngOnInit() {
    if (window.location.hostname === 'play.mtnsyr.com') { // syria case
        if (localStorage.getItem('language')) {
          this.translateService.setDefaultLang(localStorage.getItem('language'));
        } else {
          this.setDefaultLanguage('SY', this.hostName);
        }
        this.userService.handleUser();
    } else {
      this.getCountry().then((country) => {
        if (localStorage.getItem('language')) {
          this.translateService.setDefaultLang(localStorage.getItem('language'));
        } else {
          this.setDefaultLanguage(country, this.hostName);
        }
        this.userService.handleUser();
      });
    }
    // this.setTheme('gamesstore.mtn.com.ye');
    this.setTheme(this.hostName);
  }

  setTheme(hostName) {
    switch (hostName) {
      case 'gamesstore.mtn.com.ye':
        this.themeService.toggleMtnYemenTheme();
        break;

      case 'play.mtnsyr.com':
        this.themeService.toggleMtnSyriaTheme();
        break;
      default:
        this.themeService.togglePrimaryTheme();
        break;
    }
  }

  setDefaultLanguage(country, hostName) {
    if ( hostName === 'demo.playit.com' ) {
      this.translateService.setDefaultLang('en');
    } else {
      switch (country) {
        case 'YE':
        case 'SY':
        case 'EG':
          this.translateService.setDefaultLang('ar');
          break;
        default:
          this.translateService.setDefaultLang('en');
          break;
      }
    }

    localStorage.setItem('language', this.translateService.getDefaultLang());
  }
  /**
   * Subscribes to API to get country
   */
  getCountry() { 
    if(window.location.hostname === 'play.mtnsyr.com') { // syria case
          this.countryService.setCountryDetails('syria', 'SY');
          localStorage.setItem('countryCode', 'SY');

    } else {
      return new Promise((resolve, reject) => {
            this.countryService.initiateCountry().subscribe(country => {
              this.countryService.setCountryDetails(country['country_name'], country['country_code']);
              resolve(country['country_code']);
              localStorage.setItem('countryCode', 'YE');
            });
          });
    }
  }


}
