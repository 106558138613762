import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GamesService } from '../services/games/games.service';
import { UserService } from '../../shared/services/user/user.service';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss']
})
export class PlayComponent implements OnInit {

  gameDetails: any;
  userComment: string;
  userId: string;
  gameId: string;
  lang:any = 'test_lang';
  src:string;
  iframe;
  parms: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private gameService: GamesService,
    private userService: UserService,
    private spinnerService: NgxSpinnerService,
    private sanitizer: DomSanitizer
  ) {}

  @Output() public myOutput = new EventEmitter();

  ngOnInit() {
    this.getGame();
    this.lang = this.myOutput.emit('lang');
  }
  /**
   * Handles retrieving the game details
   */
  getGame() {
    this.spinnerService.show();
    this.getUserDetails();
    // console.log("INSIDE FIRST FUNCTION")
  }
  /**
   * Gets user Id and in sequence gets game id
   */
  getUserDetails() {
    this.userId = this.userService.getUserId();
    if (this.userId === undefined) {
      this.userService.getUserDetails().subscribe(user => {
        this.userId = user['user_id'];
        this.getGameId(user['user_id']);
      });
    } else {
      this.getGameId(this.userId);
    }
  }
  /**
   * Gets game ID from route parameter
   * @param userId 
   */
  getGameId(userId) {
    this.activeRoute.params.subscribe(params => {
      this.gameId = params['gameid'];
      this.getGameDetails(params['gameid'], userId);
    });
  }
  /**
   * Gets game details
   * @param gameId 
   * @param userId 
   */
  getGameDetails(gameId, userId) {
    this.gameService.getGameWithId(gameId, userId).subscribe(game => {
      this.gameDetails = game;
      this.src = this.gameDetails['game_folder'] + '/';
      if (this.gameDetails.game_vendor === 'Gamepzop') {
          this.parms = `${this.userId}&game_id=${this.gameId}&user_token=${localStorage.getItem('token')}`;
          this.src = `${this.gameDetails.game_folder}${this.parms}`;
        } else {
          if (this.gameDetails.user_skillpod) {
            this.src = `${this.gameDetails.game_folder}${this.gameDetails.user_skillpod}`;
          } else {
            this.parms = `/?user_id=${this.userId}&game_id=${this.gameId}&user_token=${localStorage.getItem('token')}`;
            this.src = `${this.gameDetails.game_folder}${this.parms}`;
          }
        }
      this.spinnerService.hide();
    });
  }
  /**
   * Handles game like and unlike 
   */
  toggleLike() {
    switch (this.gameDetails['gameIsLiked']) {
      case true:
        this.unlikeGame();
        break;
      case false:
        this.likeGame();
        break;
    }
  }
  /**
   * Unlikes game  
   */
  unlikeGame() {
    this.gameService.unlikeGame(this.gameId, this.userId).subscribe(unliked => {
      if (unliked['status']) {
        this.gameDetails['gameIsLiked'] = false;
      }
    });
  }
  /**
   * Likes game
   */
  likeGame() {
    this.gameService.likeGame(this.gameId, this.userId).subscribe(liked => {
      if (liked['status']) {
        this.gameDetails['gameIsLiked'] = true;
      }
    });
  }
  submitReview(review) {
    console.log("SUBMITTED", review);
  }

  /**
   * Updates user points in LB
   */
  updateUserPoints() {
    this.userService.updateUserLBPoints(this.userId).subscribe(
      next => {
        console.log("Success callback update leaderboard", next);
      }
    );
  }

}
