export const mtnSyriaTheme = {
  'primary-color': '#FFCB05',
  'background-color': '#1b1f2a',
  'icons-color': '#FFF',
  'text-color': 'white',
  'side-menu-background': '#2e3035',
  'side-footer-background': '#383b40',
  'loginLogo':'url(/assets/images/logos/logosyria.svg)',
  'loginLogoSize':'50%',
  'headerLogo':'url(/assets/images/logos/mtn-sy/logohomesyria.svg)',
  'headerLogoSize':'100px',
  'dark-gray-background-color': '#2e3035'
};