import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnChanges {

  constructor(private router: Router) { }
  @Input() sliderGames: Array<object>;
  @Input() numberOfSlides: number;
  @Input() autoPlay: boolean;
  @Input() withGameDescription: boolean;
  slideConfig: object;
  game :string;
  ngOnInit() {

  }
  ngOnChanges() {
    this.slideConfig = {
      slidesToShow: this.numberOfSlides,
      slidesToScroll: this.numberOfSlides,
      arrows: false,
      autoplay: this.autoPlay
    }
    
  }
  checkLogin(event, game) {
    console.log(game);
    localStorage.setItem('game_id', game.game_id);
    localStorage.setItem('game_type', game.game_type);
    localStorage.setItem('gameType', game.game_type);

  }
}
