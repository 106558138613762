<div class="faqPage">
  <h2>FAQ</h2>
  <div class="items">
    <div *ngIf="countryCode!=='YE'&& countryCode!=='SY'">
      <div class="item" *ngFor="let faq of 'FAQ CONTENT PAGE' | translate">
        <h3><i class="fa  fa-question-circle"></i> {{faq['TITLE'] | translate}}</h3>
        <p *ngFor="let text of faq['TEXT']">{{text | translate}}</p>
        <div class="small">{{faq['SMALL_TEXT'] | translate}}</div>
      </div>
    </div>
   
    <div *ngIf="countryCode=='YE'">
      <div class="item" *ngFor="let faq of 'FAQ CONTENT PAGE YE' | translate" >
        <h3><i class="fa  fa-question-circle"></i> {{faq['TITLE'] | translate}}</h3>
        <p *ngFor="let text of faq['TEXT']">{{text | translate}}</p>
        <div class="small">{{faq['SMALL_TEXT'] | translate}}</div>
      </div>
    </div>
   
    <!-- <div class="item" *ngFor="let faq of 'FAQ CONTENT PAGE YE' | translate" *ngIf="countryCode=='SY'">
      <h3><i class="fa  fa-question-circle"></i> {{faq['TITLE'] | translate}}</h3>
      <p *ngFor="let text of faq['TEXT']">{{text | translate}}</p>
      <div class="small">{{faq['SMALL_TEXT'] | translate}}</div>
    </div> -->
  </div>
</div>
