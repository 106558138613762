import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../shared/services/config/config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/config/config.service";
var SearchService = /** @class */ (function () {
    function SearchService(http, configService) {
        this.http = http;
        this.configService = configService;
        this.baseURL = this.configService.getBaseURL();
        this.hostName = this.configService.getHostName();
        this.testingHost = this.configService.getTestingHostName();
    }
    // search
    SearchService.prototype.searchGames = function (searchQuery) {
        if (window.location.hostname == 'ksa2.playit.mobi') {
            return this.http.post(this.hostName + "searchGames", { 'search_query': searchQuery, "type": 1 });
        }
        else {
            return this.http.post(this.hostName + "searchGames", { 'search_query': searchQuery });
        }
    };
    SearchService.ngInjectableDef = i0.defineInjectable({ factory: function SearchService_Factory() { return new SearchService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService)); }, token: SearchService, providedIn: "root" });
    return SearchService;
}());
export { SearchService };
