/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./play.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../iframe.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "./play.component";
import * as i6 from "@angular/router";
import * as i7 from "../services/games/games.service";
import * as i8 from "../../shared/services/user/user.service";
import * as i9 from "ngx-spinner";
var styles_PlayComponent = [i0.styles];
var RenderType_PlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlayComponent, data: {} });
export { RenderType_PlayComponent as RenderType_PlayComponent };
function View_PlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "iframe", [["allowfullscreen", ""], ["frameborder", "0"], ["mozallowfullscreen", ""], ["webkitallowfullscreen", ""]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.src)); _ck(_v, 1, 0, currVal_0); }); }
export function View_PlayComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.IframePipe, [i3.DomSanitizer]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlayComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.gameDetails == null) ? null : _co.gameDetails.game_folder); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-play", [], null, null, null, View_PlayComponent_0, RenderType_PlayComponent)), i1.ɵdid(1, 114688, null, 0, i5.PlayComponent, [i6.ActivatedRoute, i7.GamesService, i8.UserService, i9.NgxSpinnerService, i3.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlayComponentNgFactory = i1.ɵccf("app-play", i5.PlayComponent, View_PlayComponent_Host_0, {}, { myOutput: "myOutput" }, []);
export { PlayComponentNgFactory as PlayComponentNgFactory };
