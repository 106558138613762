import { UserService } from './../../../shared/services/user/user.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../shared/services/config/config.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/config/config.service";
import * as i3 from "../../../shared/services/user/user.service";
import * as i4 from "@angular/router";
var LoginService = /** @class */ (function () {
    function LoginService(http, configService, userService, router) {
        this.http = http;
        this.configService = configService;
        this.userService = userService;
        this.router = router;
        this.hostName = this.configService.getHostName();
        this.host = this.configService.getHost();
        this.hostv2 = this.configService.getHostv2();
    }
    LoginService.prototype.canActivate = function () {
        if (this.userService.getUserId()) {
            this.router.navigate(['/home/games/online']);
            return false;
        }
        else {
            return true;
        }
    };
    /**
     * Sends form consisting of user name and user's password to login the user.
     * @param userData
     */
    LoginService.prototype.loginUser = function (userData) {
        return this.http.post(this.hostName + "login", userData);
    };
    LoginService.prototype.checkUserExist = function (username) {
        return this.http.get(this.hostName + "checkusername/" + username);
    };
    LoginService.prototype.sendPasswordSMS = function (username) {
        return this.http.get(this.hostv2 + "mtnyemn/resetPassword?msisdn=" + username);
    };
    LoginService.prototype.logout = function () {
        //return this.http.get(`http://api.playit.mobi/api/v2/playit77/logout`); 
        //return this.http.get(`http://api.playit.mobi/api/v2/playit77/logout`); 
        return this.http.get(this.hostName + "logout");
    };
    /*send password sms to all portal except mtn*/
    LoginService.prototype.sendPassword = function (data) {
        return this.http.post(this.host + "forgetPassword}", data);
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient), i0.inject(i2.ConfigService), i0.inject(i3.UserService), i0.inject(i4.Router)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
