import { UserService } from './../../../shared/services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-payment-voucher',
  templateUrl: './payment-voucher.component.html',
  styleUrls: ['./payment-voucher.component.scss']
})
export class PaymentVoucherComponent implements OnInit {
  voucher:string;
  msisdn:string;
  msisdn2:string;
  showWarning:boolean=false;
  showSuccess:boolean=false;
  user_id:string;



  constructor( private userService: UserService,private router:Router,private cookieService: CookieService) {
    console.log(this.cookieService.get('userId'));

  }

  ngOnInit() {

  }

  addVoucher(){
    this.userService.addVoucher(this.msisdn, this.voucher).subscribe(
      next=>{
        if(next["status"]==true){
          this.showSuccess = true;
          this.router.navigateByUrl('home/games/online');
        }else{
          this.showSuccess = false;
          this.showWarning=true;
        }

      },
      error=>{

      }
    );
  }

  checkUser(){
    this.user_id = this.userService.getUserId();
    if (this.user_id === undefined) {
      this.userService.getUserDetails().subscribe(
        //console.log("inside getUserDetails");
        user =>{
                  console.log("user_data");
                  console.log("checkuser2");
                  this.user_id = user['user_id'];
                  this.msisdn = user['userName'];
                  console.log(this.user_id);
                  console.log(this.msisdn);
                  console.log("checkuser3");
                  //this.addVoucher();
        },
        error => {
          console.log("error");
        },
        () => {
          console.log("checkuser4");
          this.addVoucher();
        }
      );
    }else{
      this.msisdn = this.userService.getMsisdn();
      this.addVoucher();
    }

  }

}
