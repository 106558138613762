<div class="container">
    <div class="games" *ngFor="let category of gamesCategories">
        <div class="container">
            <div class="row">
                <div class="category">
                    <div class="icon-title-wrapper">
                        <ng-container *ngIf="isMtn ;else default">
                            <img class="icon" src="assets/images/category-icons/mtn/{{category.cat_name}}.svg" alt="">
                        </ng-container>
                        <ng-template #default>
                            <img class="icon" src="assets/images/category-icons/default/{{category.cat_name}}.svg" alt="">
                        </ng-template>
                        <div class="title">
                            {{category.cat_name | translate}}
                        </div>
                    </div>
                    <div class="see-all" (click)="goCategory(category.cat_name)">
                        {{'SEE ALL' | translate}}
                    </div>
                </div>
            </div>
            <div class="row">
                <app-slider [sliderGames]="category.games_list" [numberOfSlides]="4" [autoPlay]="false"
                    [withGameDescription]="true"></app-slider>
            </div>
        </div>
    </div>
</div>