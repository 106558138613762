export const defaultTheme = {
    
    //'primary-color': '#12151d',
    'primary-color': '#0099cc',
    'background-color': '#383b40',
    'header-background': '#1f1f1f',
    'footer-background': '#FFF',
    'icons-color': '#2d2d2d',
    'text-color': '#ffffff',
    'tabs-color': '#0056a9',
    'side-menu-background': '#12151d',
    'side-footer-background': '#1a1f2a',
    'dark-gray-background-color': '#2e3035',
    'loginLogo':'url(/assets/images/logos/default/playit_logo.svg)',
    'loginLogoSize':'100px',
    'headerLogo':'url(/assets/images/logos/default/playit_logo_with_text.svg)',
    'headerLogoSize':'100px',
  };
  