import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ɵConsole } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThemesService } from 'src/app/shared/services/themes/themes.service';
import { UserService } from './../../shared/services/user/user.service';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  userDetails: any;
  user_id: string;
  userName: 'userName';
  userEmail: string;
  // edit_photo = '../../../assets/images/profile-icons/avatar.png';
  remainingTime: string;
  hours: number;
  days: number;
  splitted: any;
  dirtest = false;
  isEditable = false;
  data: {};
  userData: any;
  userobject: any;
  Euserobject: any;
  file: File;
  image: any;
  name:string;
  isMtn: boolean;
  hostName: string;
  constructor(public userService: UserService, private spinnerService: NgxSpinnerService, private themingService: ThemesService,
    private route:ActivatedRoute) {
  
  }

  ngOnInit() {
    
    console.log('aya');
    this.spinnerService.show();
    this.userData = this.getUserDetails();
    this.isMtn = this.hostName === 'gamesstore.mtn.com.ye' || this.hostName === 'play.mtnsyr.com' ? true : false;
   


  }
  // ngOnchange() {
  //   console.log('inside ngOnchange');
  //   this.getUserDetails();
  // }
  /**
   * Gets user details.
   */
  getUserDetails() {
    
    let userData = this.userService.getUserData();
    console.log('enas2',userData);
    if (userData === undefined) {
        this.userService.getUserDetails().subscribe(details => {
          console.log('userDetails',this.userDetails);
        this.userDetails = details;
        this.image = this.userDetails.user_photo;
        this.name=this.userDetails.user_name;
        // if(this.userDetails.user_name !== this.userName)
        // {
        //   this.userDetails.user_name=this.userName;
        // }
        //console.log("userDetails",details);
        //aya
        // if ( this.userDetails.email === 'undefined' ) {
        //   console.log('if');
        //   this.userEmail = 'example@email.com';
        // } else {
        //   console.log('else');
        //   this.userEmail = this.userDetails.email;
        // }
        // this.remainingTime=details["remind_time"];
        // this.splitted = this.remainingTime.split(":");
        // this.days=this.splitted[0];
        // this.hours=this.splitted[1];
        // console.log('remind_time in getRemainingTime',this.remainingTime);
        this.spinnerService.hide();
      });
    } else {
      this.userDetails = this.userService.getUserData();

      this.spinnerService.hide();
    }
  }
  toggleDark() {
    this.themingService.toggleMtnYemenTheme();
  }
  toggleLight() {
    this.themingService.togglePrimaryTheme();
  }

  editProfile(userDetails) {
    this.isEditable = true; // to select mode (viewing or not --> here viewing)
    //this.Euserobject = userobject;
  }
  updateProfile() {
    this.isEditable = false; // to select mode (viewing or not --> here not viewing)
    var data = new FormData();
    data.append('user_id', this.userDetails.user_id);
    //data.append('user_name', this.userName);
    data.append('user_name', this.name);
    data.append('user_photo', this.file);
    console.log(this.file);
    // data.append('user_email', this.userEmail);
    console.log(this.userName);
    // console.log(this.userEmail);
    console.log(data);
   
    this.userService.updateUser(data).subscribe(
      next => {
        // this.userDetails.user_id = this.userName;
        // this.userDetails.user_photo = this.edit_photo;
        this.getUserDetails();
        location.reload();
        
      },
      error => {

      },
      () => {
        console.log('complete');
        //location.reload();
        //this.getUserDetails();
      });
      console.log("userDetails",this.userDetails);
      console.log("data=",data);
  }
  cancelUpdate() {
    this.isEditable = false;
    this.image = this.userDetails.user_photo;
    this.name=this.userDetails.user_name;
  }

  //update image
  // getImage(fileInput: any) {
  //   if (fileInput.target.files && fileInput.target.files[0]) {

  //       //save the selected image in our local var
  //       // this.edit_photo = fileInput.target.files[0];
  //       console.log(fileInput.target.files[0]);
  //       this.userDetails.user_photo = fileInput.target.files[0];
  //       this.getUserDetails();
  //       console.log(this.userDetails.user_photo);
  //   }
  // }
 
  
  getImage(inputValue: any): void {
    this.file = inputValue.target.files[0];
    console.log("file",inputValue.target.files);
    var myReader:FileReader = new FileReader();
  
    myReader.onloadend = (e) => {
     ///this.userDetails.user_photo = myReader.result;
     this.image = myReader.result;
    }
    myReader.readAsDataURL(this.file);
  }
}