<div *ngIf="isLoggedIn" >
  <div class="btm-navbar">
    <div class="inner">
      <a
        class="elm home"
        (click)="isAndroid()"
        routerLink="/home/games/android"
        routerLinkActive="active-home"
      >
      </a>
      <a
        class="elm my-games"
        routerLink="/favourites"
        routerLinkActive="active-my-games"
      >
      </a>
      <a
        class="elm profile"
        routerLink="/user/profile"
        routerLinkActive="active-profile"
      >
      </a>
    </div>
  </div>
</div>
