
export const mtnYemenTheme = {
    'primary-color': '#FFCB05',
    'background-color': '#383b40',
    'icons-color': '#FFF',
    'text-color': 'white',
    'side-menu-background': '#2e3035',
    'side-footer-background': '#383b40',
    'loginLogo':'url(/assets/images/logos/mtn-ye/mtnYESignInLogo.svg)',
    'loginLogoSize':'100px',
    'headerLogo':'url(/assets/images/logos/mtn-ye/mtnYEHomeLogo.svg)',
    'headerLogoSize':'150px',
    'dark-gray-background-color': '#2e3035'
    // 'mtnYEHomeLogo': url('/assets/images/login/mtn-ye/mtnYEHomeLogo.svg'),
    // 'mtnYESignInLogo': url('/assets/images/login/mtn-ye/mtnYESignInLogo.svg')
  };
