import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/login/services/login-service/login.service';
import { CountryService } from './../../shared/services/country/country.service';
import { UserService } from './../../shared/services/user/user.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
//import {ViewChild} from '@angular/core';
//import { NgxAsideModule } from 'ngx-aside';
//import { NgxAsideComponent } from 'ngx-aside/lib/aside.component'




@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  userDetails: any;
  user_id: any;
  countryCode: any;
  navDir: String = 'left';
  url: string;
  code: number;
  MobOperatingSystem :string;
  constructor(
    private userService: UserService,
    private router: Router,
    private loginService: LoginService,
    private countryService: CountryService,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    
    // this.getCountry();
    console.log(window.location.hostname);
    if(true){
      this.countryCode = "SA";
      console.log(this.countryCode);
    } else {
      this.countryCode = localStorage.getItem('countryCode');
    }

    console.log(this.countryCode);
    this.getUserDetails();
    this.getSidenavDir();
  }
  getSidenavDir() {
    if (document.querySelector('.playit-container.rtl')) {
      this.navDir = 'right';
      return;
    }
    this.navDir = 'left';
    return;
  }

  /**
   * Gets user details and sets it in local variable
   */
  getUserDetails() {
    // const userData = this.userService.getUserData();
    // this.user_id = userData['user_id'];
    // return userData;
    const userData = this.userService.getUserData();
    console.log('enas2', userData);
    if (userData === undefined) {
        this.userService.getUserDetails().subscribe(details => {
        this.userDetails = details;
        
      });
    } else {
      this.userDetails = this.userService.getUserData();
    }
    console.log('userDetails',this.userDetails);
  }
  /**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
getMobileOperatingSystem() {
  let userAgent = navigator.userAgent || navigator.vendor;

      // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      console.log('Windows Phone');
        return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      console.log('Android');
        return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      console.log('iOS');
      if (!!window.indexedDB) { return 'iOS 8 and up'; }
      
      
      if (!!window.matchMedia) { return 'iOS 5'; }
      if (!!window.history && 'pushState' in window.history) { return 'iOS 4'; }
      return 'iOS 3 or earlier';
      
      
    }

    return 'unknown';
}

  /*
  ** logout
  ** no work :(
  */
  // logout() {
  //   console.log('progress');
  //   this.loginService.logout().subscribe(next => {
  //     localStorage.clear();
  //     var newWindow = window.open();
  //     console.log('success', next);
  //     //newWindow.location.href = '/login';
  //   },error=>{
  //     console.log(error)
  //   });
  // }

  /*logout*/
  // log_out(){
  //console.log("customM",localStorage.getItem('customM'));

  // console.log("headerView",localStorage.getItem('headerView'));
  // console.log("inside logout");
  // localStorage.clear();
  // this.cookieService.deleteAll();
  //this.cookieService.delete("userId");
  //this.ngxAsidePanelLeft.hide();
  // this.router.navigate(['/login']);
  //console.log(!this.userService.getUserId() && !this.cookieService.check('userId'));
  // }

  /*
  ** unsubscribe
  */
 unsubscribe() {
  this.user_id = this.userService.getUserId();
  /*unsubscribe using sms in yemen*/
  if (this.countryCode ===  'SA') {
    this.countryCode = 'SA';
    console.log('user in SA');
    this.MobOperatingSystem=this.getMobileOperatingSystem();
    this.userService.unsubscribeKSA(this.userDetails.userName).subscribe(
      next => {
        console.log(next);
        if ( next['code'] !== null) {
          this.code = next['code'];
          console.log('MobOperatingSystem',this.MobOperatingSystem);
          // if (this.MobOperatingSystem === 'iOS') {
          //   console.log('iOS2');
          //   switch (this.code) {
          //     case 801281:
          //         this.url = 'sms:801281&body=U 1';
          //         break;
          //     case 708370:
          //         this.url = 'sms:708370&body=U 1';
          //         break;
          //     case 670611:
          //         this.url = 'sms:670611&body=U 10';
          //         break;
          //   }
          // } else if (this.MobOperatingSystem === 'iOS') {
          //   console.log('iOS2');
          //   switch (this.code) {
          //     case 801281:
          //         this.url = 'sms:801281&body=U 1';
          //         break;
          //     case 708370:
          //         this.url = 'sms:708370&body=U 1';
          //         break;
          //     case 670611:
          //         this.url = 'sms:670611&body=U 10';
          //         break;
          //   }
          // } else {
          //   console.log('android');
            switch (this.code) {
              case 801281:
                  this.url = 'sms:801281;?&body=U 1';
                  break;
              case 708370:
                  this.url = 'sms:708370;?&body=U 1';
                  break;
              case 670611:
                  this.url = 'sms:670611;?&body=U 10';
                  break;
            }
          // }
          console.log(this.url);
          window.location.href = this.url;
      }
     
    }

    );
    
  } else if (this.countryCode === 'YE') {
    console.log('user in ye');
     const link = document.getElementById('sms');
     console.log(this.userDetails.plan);
      if (this.userDetails.plan === 'Daily') {
        console.log('daily');
        this.url = 'sms:1133?body=%201';
        window.location.href = this.url;
      } else if (this.userDetails.plan === 'Weekly') {
        this.url = 'sms:1133?body=%202';
        window.location.href = this.url;
      }
  } else {
    this.userService.unsubscribe(this.user_id).subscribe(next => {
      if (next['status'] === true) {
        this.userService.logout(this.user_id).subscribe(
          next => {
            console.log('inside if');
            localStorage.clear();
            this.cookieService.delete('userId');
            this.cookieService.deleteAll();
            window.location.href = '/login';
          });
      } else {
        console.log('error unsubscribing user.')
      }});
  }


 
}
  logout() {
    console.log('logout1');

    this.user_id = this.userService.getUserId();
    console.log('user_id', this.user_id);
    this.userService.logout(this.user_id).subscribe(
      next => {
        console.log('logout');
        localStorage.clear();
        
        if (this.cookieService.check('userId')){
          console.log('logout2');
          this.cookieService.deleteAll();
          this.cookieService.set('userId','', undefined,'/');
          //this.cookieService.delete('user_id');
        }
 //       window.location.href = '/login';
      }
    )


  }
}
