<div class="result-search">

  <section *ngIf="notfound" class="no-result"> {{'NO RESULT' | translate}} : <strong>{{searchInput}}</strong></section>
  <section *ngIf="!notfound" class="no-result"> {{'SEARCH RESULTS' | translate}} : <strong>{{searchInput}}</strong></section>
  <div class="games">
    <div class="game" (click)="checkLogin(game.game_type,game.game_id)" *ngFor="let game of games">
      <div class="img">
        <img [src]="game.game_thumb" alt="">
      </div>
      <div class="name">
        {{game.game_name }}
      </div>
    </div>
  </div>
</div>

<!-- href="{{game.game_folder}}" -->