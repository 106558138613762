import { UserService } from './../../shared/services/user/user.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-verify-subscribtion',
  templateUrl: './verify-subscribtion.component.html',
  styleUrls: ['./verify-subscribtion.component.scss']
})
export class VerifySubscribtionComponent implements OnInit {

  constructor(private userService: UserService) { }
  pinCode: any;
  pinCode1: any;
  pinCode2: any;
  pinCode3: any;
  pinCode4: any;
  redirect = false;
  userId: string;
  message: string;
  isError = false;

  ngOnInit() {
    this.userId=localStorage.getItem('userId');
    console.log('userId', this.userId);
  }
  keytab(event){
    let nextInput = event.srcElement.nextElementSibling; // get the sibling element

    var target = event.target || event.srcElement;
    var id = target.id
    console.log(id.maxlength); // prints undefined

    if(nextInput == null)  // check the maxLength from here
        return;
    else
        nextInput.focus();   // focus if not null
  }
  getPinCode() {
    this.pinCode = this.pinCode1 + this.pinCode2 + this.pinCode3 + this.pinCode4;
    console.log(this.pinCode);
  }
  finish() {
    this.getPinCode();
    const userData = new FormData;
    userData.append('userId', this.userId);
    userData.append('code', this.pinCode);
    this.userService.verifySubscribtion(userData).subscribe(
      next => {
        if (next['status'] === true) {
          this.pinCode = '';
          this.redirect = true;
          this.userService.getUserToken(next['userId'], this.redirect);
        } else {
          if(next['message'] === 'Code Not correct')
          this.isError=true;
          ;
        }
        

      }
    );
  }
  resendCode() {
    this.userService.resendCode(this.userId).subscribe();
  }

}
