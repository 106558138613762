<div class="leaderboard-container">

  <!-- <div class="container" [hidden]="countryCode=='EG'">
      <div class="playit-container">
          <div class="titles-container" [ngStyle]="{'color': countryCode == 'YE' ? 'black' : 'aqua'}">
              <div class="container">
                  <div class="col-xs-offset-3 col-xs-5">
                      {{'NAME' |translate}}
                  </div>
                  <div class="col-xs-2">
                      {{'POINTS' | translate}}
                  </div>
                  <div class="col-xs-2">
                      {{'RANK' | translate}}
                  </div>
              </div>
          </div>
          <div *ngFor="let player of leaderboardData ">
              <div class="player-container" [ngStyle]="{'color': countryCode == 'YE' ? 'black' : 'white'}">
                  <div class="container">
                      <div class="row">
                          <div class="col-xs-3">
                              <div class="player-image" *ngIf="player.photo!=null">
                                  <img src="{{player.photo}}" alt="">
                              </div>
                              <div class="player-image" *ngIf="player.photo == null">
                                  <img src="assets/images/person.png" alt="">
                              </div>
                          </div>
                          <div class="col-xs-5">
                              <div class="player-name">
                                  {{player.name}}
                              </div>
                          </div>
                          <div class="col-xs-2">
                              <div class="player-count">
                                  {{player.count}}
                              </div>
                          </div>
                          <div class="col-xs-2">
                              <div class="player-rank">
                                  {{player.rank}}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <pagination-controls previousLabel="{{ 'PREVIOUS' | translate }}" nextLabel="{{ 'NEXT' | translate }}"
              class="pagination-bar" (pageChange)="p = $event"></pagination-controls>
      </div>
  </div> -->
  <div *ngIf="countryCode=='EG'">
      <ngx-tabset>
          <ngx-tab tabTitle="{{'LEADERBOARD' | translate}}">
              <table class="table">
                  <thead>
                      <tr>
                          <th scope="col">{{'RANK'|translate}}</th>
                          <th scope="col">{{'NUMBER'|translate}}</th>
                          <th scope="col">{{'Points'|translate}}</th>
                      </tr>
                  </thead>
                  <tbody>
                      <!-- <tr *ngFor="let player of leaderboardData" [hidden]="leaderboardData=='false'" [ngClass]="{'current-user': CurrentUser }"> -->
                      <tr *ngFor="let player of leaderboardData" [hidden]="showCurrentUser" [ngClass]="{'current-user': showCurrentUser }">
                          <td>{{player.rank}}</td>
                          <td>{{player.name}}</td>
                          <td>{{player.count}}</td>
                      </tr>
                      <ng-container *ngIf="showCurrentUser">
                      <tr  *ngFor="let player of leaderboardData" >
                              <td>{{player.rank}}</td>
                              <td>{{player.name}}</td>
                              <td>{{player.count}}</td>
                      </tr>
                     </ng-container>
                      <!-- <tr class="current-user" *ngIf="CurrentUser!=false">
                          <td class="rank-current-user">{{CurrentUser?.rank}}</td>
                          <td>{{CurrentUser?.name}}</td>
                          <td>{{CurrentUser?.count}}</td>
                      </tr> -->
                      <!-- <tr class="current-user" [hidden]=" CurrentUser!=false || user_id==='guest' ">
                          <td class="rank-current-user">0</td>
                          <td>{{CurrentUser?.name}}</td>
                          <td>0</td>
                      </tr> -->
                  </tbody>
              </table>
          </ngx-tab>
          <ngx-tab tabTitle="{{'PRIZES&RULES' | translate}}">
              <div class="week-prize">
                  <div class="week-prize-title">
                      {{'WEEK PRIZE'|translate}}
                  </div>
                  <div class="week-prize-body">
                      <img src="assets/images/cards/mobile.png" />
                  </div>
              </div>
              <div class="week-winner">
                  <div class="week-winner-title">
                      {{'WEEK WINNER'|translate}}
                  </div>
                  <div class="week-winner-title">
                      aya
                  </div>
                  <div class="week-winner-body">
                      <table class="table">
                          <tbody>
                              <tr class="current-user">
                                  <td>
                                      <div class="outer-div-stamp">
                                          <div class="inner-div-stamp">
                                              <img src="assets/images/cards/badge.png" />
                                          </div>
                                      </div>
                                  </td>
                                  <td>{{recordsToOredoo?.name}}</td>
                                  <td>plays</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
              <div class="terms">
                  <div class="terms-title">
                      {{'TERMS AND CONDITIONS'|translate}}
                  </div>
                  <div class="terms-body">
                      {{recordsToOredoo?.description}}
                  </div>
              </div>
          </ngx-tab>

      </ngx-tabset>
  </div>
</div>
