<div class="categoryPage">
  <div class="dark-balck-background">
    <div class="title">
      {{catName | translate}}
    </div>
  </div>
  <div class="games">
    <div class="game" (click)="checkLogin(game.game_type,game.game_id)" *ngFor="let game of games">
      <div class="img">
        <img [src]="game.game_thumb" alt="">
      </div>
      <div class="name">
        {{game.game_name }}
      </div>
    </div>
  </div>
</div>