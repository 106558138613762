import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
@Component({
  selector: "app-bottom-nav",
  templateUrl: "./bottom-nav.component.html",
  styleUrls: ["./bottom-nav.component.scss"]
})
export class BottomNavComponent implements OnInit {
  isLoggedIn = false;

  constructor(private cookieService: CookieService) {}

  ngOnInit() {
    if (this.cookieService.check("userId")) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

  isAndroid() {
    localStorage.setItem('gameType', 'andriod');
   }
}
