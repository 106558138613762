import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../shared/services/user/user.service';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent implements OnInit {
  games: any;
  catName: string;
  typeName: string; // online or android
  constructor(
    private spinnerService: NgxSpinnerService,
    private cookieService: CookieService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    this.getFavGames();
  }

  getFavGames() {
    this.spinnerService.show();

    const userID = this.cookieService.get('userId');
    this.userService.getFavGames(userID).toPromise()
      .then((res) => {
        this.games = res[`user_games`];
        this.spinnerService.hide();

      });

  }


  checkLogin(gameType, gameId) {
    if (this.userService.getUserId()) {
      this.router.navigateByUrl('game/' + gameType + '/' + gameId);
    } else {
      this.router.navigateByUrl('login');
    }
  }

}
