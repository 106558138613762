/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bottom-nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./bottom-nav.component";
import * as i5 from "ngx-cookie-service";
var styles_BottomNavComponent = [i0.styles];
var RenderType_BottomNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BottomNavComponent, data: {} });
export { RenderType_BottomNavComponent as RenderType_BottomNavComponent };
function View_BottomNavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "btm-navbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "a", [["class", "elm home"], ["routerLink", "/home/games/android"], ["routerLinkActive", "active-home"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.isAndroid() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(5, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 4, "a", [["class", "elm my-games"], ["routerLink", "/favourites"], ["routerLinkActive", "active-my-games"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, [[4, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(10, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(13, 0, null, null, 4, "a", [["class", "elm profile"], ["routerLink", "/user/profile"], ["routerLinkActive", "active-profile"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, [[6, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(15, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 })], function (_ck, _v) { var currVal_2 = "/home/games/android"; _ck(_v, 4, 0, currVal_2); var currVal_3 = "active-home"; _ck(_v, 5, 0, currVal_3); var currVal_6 = "/favourites"; _ck(_v, 9, 0, currVal_6); var currVal_7 = "active-my-games"; _ck(_v, 10, 0, currVal_7); var currVal_10 = "/user/profile"; _ck(_v, 14, 0, currVal_10); var currVal_11 = "active-profile"; _ck(_v, 15, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 9).target; var currVal_5 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_4, currVal_5); var currVal_8 = i1.ɵnov(_v, 14).target; var currVal_9 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_8, currVal_9); }); }
export function View_BottomNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoggedIn; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BottomNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bottom-nav", [], null, null, null, View_BottomNavComponent_0, RenderType_BottomNavComponent)), i1.ɵdid(1, 114688, null, 0, i4.BottomNavComponent, [i5.CookieService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BottomNavComponentNgFactory = i1.ɵccf("app-bottom-nav", i4.BottomNavComponent, View_BottomNavComponent_Host_0, {}, {}, []);
export { BottomNavComponentNgFactory as BottomNavComponentNgFactory };
