import { Component, OnInit } from '@angular/core';
import {CountryService} from '../../../shared/services/country/country.service';
import {Router} from '@angular/router';
import {UserService} from '../../../shared/services/user/user.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-payment-fawry',
  templateUrl: './payment-fawry.component.html',
  styleUrls: ['./payment-fawry.component.scss']
})
export class PaymentFawryComponent implements OnInit {

  msisdn:string;
  currCode:string;
  package:string = 'week';
  name:string;
  email:string;
  error:boolean = false;
  error_msg:string='';
  success:boolean = false;
  success_msg:string = '';
  countryCode: string;
  user_id: string;
  result: any;
  dt = new Date();

  constructor(
    private countryService: CountryService,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService
  ){}

  fawrySubscribe() {
    if(this.msisdn && this.package) {
      var data = new FormData();
      data.append('currencycod','EGP');
      data.append('customermobile', this.msisdn);
      data.append('customeremail', this.email);
      data.append('items[]', this.package);
      this.countryService.fawrySubscribe(data).subscribe((res) => {
        console.log(res['referenceNumber']);
        this.result = res;

        if (res['referenceNumber']) {
          this.dt.setHours( this.dt.getHours() + 12 );
          this.success = true;
          this.success_msg = this.translateService.instant("YOUR_ORDER")+ res['referenceNumber'] + this.translateService.instant("PENDING_IN_FAWRY");
          this.error_msg = '';

        } else {
          this.success = false;
          this.error = true;
          this.success_msg ='';
          this.error_msg = 'phone number and package are required';
        }
      },(err) => {
        this.error = true;
        this.error_msg = 'enter valid data!';
      });
    } else {
      this.error = true;
      this.error_msg = 'phone number and package are required';
    }
  }

  checkUser(){
    this.user_id = this.userService.getUserId();
    if (this.user_id === undefined) {
      this.userService.getUserDetails().subscribe(
        user => {
          this.user_id = user['user_id'];
          this.msisdn = user['userName'];
        },
        error => {
          console.log("error");
        },
        () => {
          console.log("checkuser4");
        }
      );
    } else {
      this.msisdn = this.userService.getMsisdn();
    }

  }

  ngOnInit() {
    this.checkUser();
  }

}
