import { GamesService } from '../services/games/games.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from './../../shared/services/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  games: any;
  catName: string;
  typeName: string; // online or android
  urlGame: string;
  constructor(
    private activeRoute: ActivatedRoute,
    private gamesService: GamesService,
    private spinnerService: NgxSpinnerService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
    if (localStorage.getItem('headerView') !== 'sharedHeader') {
      location.reload();
    }
    this.captureSearchGame();
  }
  /**
   * Gets search text from params
  */
  captureSearchGame() {
    this.activeRoute.params.subscribe(params => {
      this.spinnerService.show();
      this.typeName = params['type']; //android or online
      this.getAllGames_Type(params['category_name']);

      console.log(params)
      this.catName = params['category_name'];
     
    });
  }
   /* 
  ** get all game in category
  */
 getAllGames_Type(type) {
   console.log("typeName",this.typeName);
   if(this.typeName==='android'){
    this.gamesService.getAllGames_Type_android(type,1).subscribe( 
      games => {
      this.spinnerService.hide();
       this.games = games['game_list'];
    });

   }
   else if (this.typeName === 'online'){
    this.gamesService.getAllGames_Type_online(type,1).subscribe( 
      games => {
      this.spinnerService.hide();
       this.games = games['game_list'];
    });

   } else if (this.typeName === 'lobby'){
    this.gamesService.getAllGames_Type_all(type,1).subscribe( 
      games => {
      this.spinnerService.hide();
       this.games = games['game_list'];
    });

   }

 
}
  
  /* 
  ** get all game in category
  */
  /*getAllGames(categoryName) {
    this.gamesService.getAllGames(2).subscribe( games => {
      this.spinnerService.hide();
      for(let i = 0; i < games['game_list'].length; i++) {
        if (games['game_list'][i]['cat_name'] == categoryName) {
          this.games = games['game_list'][i]['games_list'];
        }
      }
    });
  }*/
  getAllGames(categoryName) {
    this.gamesService.getLobbyGames(categoryName).subscribe( games => {
      console.log(categoryName, games['game_list']);
      this.games = games['game_list'];
      this.spinnerService.hide();
    });
  }

  checkLogin(gameType, gameId) {
    console.log('inside checkLogin');
    if (this.userService.getUserId()) {
      this.router.navigateByUrl('game/' + gameType + '/' + gameId);
    } else {
      localStorage.setItem('urlGame', 'game/' + gameType + '/' + gameId);
      console.log(localStorage.getItem('urlGame'));
      this.router.navigateByUrl('login');
    }
  }

}
