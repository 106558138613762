/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i3 from "ngx-spinner";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./layout/header/header.component.ngfactory";
import * as i6 from "./layout/header/header.component";
import * as i7 from "./shared/services/country/country.service";
import * as i8 from "./shared/services/user/user.service";
import * as i9 from "@angular/router";
import * as i10 from "@angular/common";
import * as i11 from "./layout/bottom-nav/bottom-nav.component.ngfactory";
import * as i12 from "./layout/bottom-nav/bottom-nav.component";
import * as i13 from "ngx-cookie-service";
import * as i14 from "./app.component";
import * as i15 from "./shared/services/themes/themes.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ngx-spinner", [["fullScreen", "true"], ["type", "pacman"]], null, null, null, i2.View_NgxSpinnerComponent_0, i2.RenderType_NgxSpinnerComponent)), i1.ɵdid(1, 770048, null, 0, i3.NgxSpinnerComponent, [i3.NgxSpinnerService, [8, null]], { type: [0, "type"], fullScreen: [1, "fullScreen"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-header", [], null, null, null, i5.View_HeaderComponent_0, i5.RenderType_HeaderComponent)), i1.ɵdid(5, 114688, null, 0, i6.HeaderComponent, [i4.TranslateService, i7.CountryService, i8.UserService, i9.Router, i10.Location], { headerView: [0, "headerView"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-bottom-nav", [], null, null, null, i11.View_BottomNavComponent_0, i11.RenderType_BottomNavComponent)), i1.ɵdid(9, 114688, null, 0, i12.BottomNavComponent, [i13.CookieService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pacman"; var currVal_1 = "true"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.headerView; _ck(_v, 5, 0, currVal_3); _ck(_v, 7, 0); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "playit-container ", i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("DIRECTION")), ""); _ck(_v, 2, 0, currVal_2); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i14.AppComponent, [i4.TranslateService, i7.CountryService, i8.UserService, i15.ThemesService, i3.NgxSpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
