import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigService } from '../../../shared/services/config/config.service';


@Injectable({
  providedIn: 'root'
})
export class CategoryTabsResolverService implements Resolve<any> {
  hostName: string;
  constructor(private http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private configService: ConfigService) {
      this.hostName = this.configService.getHostName();

     }

  resolve() {
    this.spinnerService.show();
    if(window.location.hostname == 'ksa2.playit.mobi'){
        return this.http.get(`${this.hostName}getGames?type=easy`)
          .toPromise()
          .then((res) => {
            this.spinnerService.hide();
            return res[`games_list`];
        });
    }else{
        return this.http.get(`${this.hostName}getGames`)
        .toPromise()
        .then((res) => {
          this.spinnerService.hide();
          return res[`games_list`];
      });
    }

  }
}
