import { UserService } from './../user/user.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from "rxjs";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private userService: UserService) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: `Bearer ${this.userService.getToken()}`
      }
    });

    return next.handle(req);
  }
}
