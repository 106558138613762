import { Injectable } from '@angular/core';
import { defaultTheme } from '../../themes/default-theme.js';
import { mtnSyriaTheme } from '../../themes/mtn-syria-theme.js';
import { mtnYemenTheme } from '../../themes/mtn-yemen-theme.js';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {

  constructor() { }


  togglePrimaryTheme() {
    this.setTheme(defaultTheme);
  }
  toggleMtnYemenTheme() {
    this.setTheme(mtnYemenTheme);
  }
  toggleMtnSyriaTheme() {
    this.setTheme(mtnSyriaTheme);
  }

  private setTheme(theme: {}) {
    Object.keys(theme).forEach(k => {
      document.documentElement.style.setProperty(`--${k}`, theme[k]);
    }
    );
  }
}
