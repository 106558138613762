import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GamesService } from '../services/games/games.service';
import { UserService } from '../../shared/services/user/user.service';
import { NgxSpinnerService } from '../../../../node_modules/ngx-spinner';


@Component({
  selector: 'app-game-details',
  templateUrl: './game-details.component.html',
  styleUrls: ['./game-details.component.scss']
})

export class GameDetailsComponent implements OnInit {
  gameDetails;
  gameDetailsTranslated: {};
  userComment: string;
  userId: string;
  gameId: string;
  lang: any = 'test_lang';
  isMtn: boolean;
  game_size: any;
  countryCode: string;
  language: string;
  gameThumb: any;
  favourite: any;
  gameType: any;
  gameNameAr: any;
  gameName: any;
  gameVendor: any;
  gameFolder:any;
  gameCat: any;
  gameDescribe: any;
  gameDescribeAr: any;
  hostName: string;
  constructor(
    private activeRoute: ActivatedRoute,
    private gameService: GamesService,
    private userService: UserService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  @Output() public myOutput = new EventEmitter();

  ngOnInit() {
   // this.spinnerService.show();
  this.getGame();
  console.log(localStorage.getItem('headerView'));
  if (localStorage.getItem('headerView') !== 'sharedHeader') {
     location.reload();
 }

  this.isMtn = this.hostName === 'gamesstore.mtn.com.ye' || this.hostName === 'play.mtnsyr.com' ? true : false;

  console.log(this.isMtn , this.hostName );

    // this.userService.getUserDetails().subscribe(
    //   next => {     
    //     console.log('11111111111');
    //     localStorage.setItem('userId', next[`user_id`]);
    //   this.countryCode = localStorage.getItem('countryCode');
    //   this.language = localStorage.getItem('language');
    //   // this.gameType = localStorage.getItem('game_type');
    //   if (localStorage.getItem('headerView') !== 'sharedHeader') {
    //     location.reload();
    //   }
    //     this.activeRoute.params.subscribe(params => {
    //         this.gameService.getGameWithId(params['gameid'], localStorage.getItem('userId')).subscribe(
    //           game => {
    //             console.log('game',game);
    //           this.gameDetailsTranslated = game;
    //           this.Liked = game['gameIsLiked'];
    //           this.gameNameAr = game['game_name_ar'];
    //           this.gameName = game['game_name'];
    //           console.log('gameName', this.gameName);
    //           this.gameThumb = game['game_thumb'];
    //           this.gameVendor = game['game_vendor'];
    //           this.gameCat = game['game_cat'];
    //           this.gameDescribeAr = game['game_desc_ar'];
    //           this.gameDescribe = game['game_desc'];
    //           this.game_size = game['game_size'] / 1000000;
    //           this.game_size = this.game_size.toFixed(2);
    //         });
    //       }
    //     );
    //   this.lang = this.myOutput.emit('lang');
    //   const hostName = window.location.hostname;
    //   this.isMtn = hostName === 'gamesstore.mtn.com.ye' || hostName === 'play.mtnsyr.com' ? true : false;
    //   //this.spinnerService.hide();
    // } ,
    // error => {
    //   //this.spinnerService.hide();
    // }
  
//  );

  console.log('00000000');
  }
  /**
   * Handles retrieving the game details
   */
  getGame() {
    this.spinnerService.show();
    this.getUserDetails();
    // console.log("INSIDE FIRST FUNCTION")
  }
  /**
   * Gets user Id and in sequence gets game id
   */
  getUserDetails() {
    this.userId = this.userService.getUserId();
    if (this.userId === undefined) {
      this.userService.getUserDetails().subscribe(user => {
        this.userId = user['user_id'];
        this.getGameId(user['user_id']);
      });
    } else if (this.userId === '') {
      window.location.href = '/login';
    } else {
      this.getGameId(this.userId);
    }
  }
  /**
   * Gets game ID from route parameter
   * @param userId
   */
  getGameId(userId) {
 
    console.log('userId in game-details',userId);
    this.countryCode = localStorage.getItem('countryCode');
    this.language = localStorage.getItem('language');
    this.gameType = localStorage.getItem('game_type');
    console.log(this.gameType);
    this.activeRoute.params.subscribe(params => {
      this.gameId = params['gameid'];
      this.gameService.getGameWithId(params['gameid'], userId).subscribe(
        game => {
          console.log('game',game);
        this.gameDetailsTranslated = game;
        console.log('gameIsFav', game['gameIsFav']);
        this.favourite = game['gameIsFav'];
        this.gameNameAr = game['game_name_ar'];
        this.gameName = game['game_name'];
        console.log('gameName', this.gameName);
        this.gameThumb = game['game_thumb'];
        this.gameVendor = game['game_vendor'];
        this.gameFolder = game['game_folder'];
        this.gameCat = game['game_cat'];
        this.gameDescribeAr = game['game_desc_ar'];
        this.gameDescribe = game['game_desc'];
        this.game_size = game['game_size'] / 1000000;
        this.game_size = this.game_size.toFixed(2);
        this.gameType= game['game_type'];
        localStorage.setItem('game_type',game['game_type']);
        localStorage.setItem('gameType',game['game_type']);
        this.spinnerService.hide();
      },
      error => {
        this.spinnerService.hide();
      }
    );
    });
  }
  /**
   * Gets game details
   * @param gameId
   * @param userId
   */
  // getGameDetails(gameId, userId) {
  //   this.gameService.getGameWithId(gameId, userId).subscribe(
  //     game => {
  //     this.gameDetails = game;
  //     console.log('gameDetails[gameIsLiked]', this.gameDetails['gameIsLiked']);
  //     this.gameDetailsTranslated = game;
  //     console.log('gameDetails', this.gameDetails);
  //     this.game_size = this.gameDetails['game_size'] / 1000000;
  //     this.game_size = this.game_size.toFixed();
  //     // this.spinnerService.hide();
  //   },
  //   error => {

  //   },
  //   () => {
  //       this.checkLanguage(this.language);
  //       this.translategameDetails();
  //   }
  //   );
  // }
  /**
   * Handles game like and unlike
   */
  toggleLike() {
    //console.log(this.gameDetails['gameIsLiked']);
    switch (this.favourite) {
      case true:
        this.unlikeGame();
        break;
      case false:
        this.likeGame();
        break;
    }
  }
  /**
   * Unlikes game
   */
  unlikeGame() {
    this.gameService.unlikeGame(this.gameId, this.userId).subscribe(next => {
      if (next['status']) {
        this.favourite = false;
      }
    });
  }
  /**
   * Likes game
   */
  likeGame() {
    this.gameService.likeGame(this.gameId, this.userId).subscribe(next => {
      if (next['status']) {
        this.favourite = true;
      }
    });
  }
  submitReview(review) {
    console.log("SUBMITTED", review);
  }
  /**
   *
   */
  playGame() {
    switch (this.gameType) {
      case 'easy':
        this.playHTML();
        break;
      case 'andriod':
        this.downloadGame();
        break;
    }
  }
  /**
   * Updates user points in LB
   */
  updateUserPoints() {
    this.userService.updateUserLBPoints(this.userId).subscribe(
      next => {
        console.log("Success callback update leaderboard", next);
      }
    );
  }
  /**
   *Redirects to game-play component
   */
  playHTML() {
    this.router.navigateByUrl('play/' + this.gameType + '/' + this.gameId);
  }
  /**
   * Downloads game
   */
  downloadGame() {
    this.updateUserPoints();
    var newWindow = window.open();
    this.gameService.downlaodGame(this.gameId).subscribe(data => {
      console.log("Game data", data);
      newWindow.location.href = this.gameFolder;
      setTimeout(() => {
        (function () {
          newWindow.close();
        })();
      }, 3000);
    });
  }
  // translategameDetails() {
  //   this.translateService.onLangChange.subscribe(language => {
  //     this.checkLanguage(language['lang']);
  //   });
  // }

  // checkLanguage(lang) {
  //   this.gameDetailsTranslated = this.gameDetails;
  //   // if (this.prize_Data == undefined || this.prize_Data["name"] == null || this.prize_Data["description"] == null) {
  //   //   this.fullbackPrize = true;
  //   // }
  //   if (lang === 'ar') {
  //     console.log('inside ar');
  //     console.log("gameDetails['game_name_ar']",this.gameDetails['game_name_ar']);
  //     if (this.gameDetails['game_name_ar'] !== null && this.gameDetails['game_name_ar'] !== 'undefined') {
  //       console.log('inside name ar', this.gameDetails['game_name_ar']);
  //       this.gameDetailsTranslated['game_name'] = this.gameDetails['game_name_ar'];
  //     } else {
  //       console.log('inside else name ar', this.gameDetails['game_name']);
  //       this.gameDetailsTranslated['game_name'] = this.gameDetails['game_name'];
  //     }
  //     if (this.gameDetails['game_desc_ar'] !== null && this.gameDetails['game_desc_ar'] !== 'undefined') {
  //       this.gameDetailsTranslated['game_desc'] = this.gameDetails['game_desc_ar'];
  //     } else {
  //       this.gameDetailsTranslated['game_desc'] = this.gameDetails['game_desc'];
  //     }
  //   } else {
  //     this.gameDetailsTranslated['game_name'] = this.gameDetails['game_name'];
  //     this.gameDetailsTranslated['game_desc'] = this.gameDetails['game_desc'];

  //   }
  // }
}
