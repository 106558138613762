<div class="body">
  <div class="header">
    <h3>{{'PAY SMS' | translate}}</h3>
    <img src="/assets/images/pay/pay4.png" />
  </div>
  <div class="content">
      <div class="telecommunication">
       <img src="/assets/images/pay/vodafone.png" height="75" />
       <img src="/assets/images/pay/etisalat.png" height="75" />
       <img src="/assets/images/pay/orange.png" height="75" />
      </div>
      <div>
          <a href="sms:2210?body=1">
            <button>{{'paySMS' | translate}}</button>
          </a>
      </div>
  </div>
</div>