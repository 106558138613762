import { defaultTheme } from '../../themes/default-theme.js';
import { mtnSyriaTheme } from '../../themes/mtn-syria-theme.js';
import { mtnYemenTheme } from '../../themes/mtn-yemen-theme.js';
import * as i0 from "@angular/core";
var ThemesService = /** @class */ (function () {
    function ThemesService() {
    }
    ThemesService.prototype.togglePrimaryTheme = function () {
        this.setTheme(defaultTheme);
    };
    ThemesService.prototype.toggleMtnYemenTheme = function () {
        this.setTheme(mtnYemenTheme);
    };
    ThemesService.prototype.toggleMtnSyriaTheme = function () {
        this.setTheme(mtnSyriaTheme);
    };
    ThemesService.prototype.setTheme = function (theme) {
        Object.keys(theme).forEach(function (k) {
            document.documentElement.style.setProperty("--" + k, theme[k]);
        });
    };
    ThemesService.ngInjectableDef = i0.defineInjectable({ factory: function ThemesService_Factory() { return new ThemesService(); }, token: ThemesService, providedIn: "root" });
    return ThemesService;
}());
export { ThemesService };
