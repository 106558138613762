import { GiftsService } from './../services/gifts/gifts.service';
import { GamesService } from './../services/games/games.service';
import { GamesModule } from './../games.module';
import { Component, OnInit } from '@angular/core';
import {TabsModule} from 'ngx-tabset';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {UserService} from '../../shared/services/user/user.service';
import {CountryService} from '../../shared/services/country/country.service';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {

  constructor(private gameService: GamesService,private countryService: CountryService, private userService: UserService, private gifts: GiftsService, private router: Router, private translateService: TranslateService) { }
  leaderboardData: any;
  defaultleaderboardData: any;
  CurrentUser: any;
  user_id: any;
  showCurrentUser:boolean=false;

  //records: object;
  recordsToOredoo = {};
  leaderboardPrize: any;

  language: string;
  countryCode: string;
  p: number = 1;
  operatorId: string;
  ngOnInit() {
    this.checkUser();
    this.getCountry();
    this.language = this.translateService.currentLang;
    console.log("currentLang in init", this.language);
    this.getTopPlayers();
    this.getLDPrize();
  }
  getLDPrize() {
    this.gifts.getLeaderboardPrize().subscribe(data => {
      console.log("we got", data);
      this.leaderboardPrize = data;
      console.log('records from getLDPrize', this.leaderboardPrize);
    },error => {
      console.log("error");
    },
      () => {
        this.checkLanguage(this.language);
        this.translatePrizeData();
      });
  }
  getOperatorId() {
    this.operatorId = 'QMGmvVF9Vb';
    // if (localStorage.getItem("user")) {
    //   this.operatorId = JSON.parse(localStorage.getItem("user")).operator_id;
    //   console.log("not guest");
    // }
    // else {
    //   this.operatorId = '';
    //   console.log("operator", this.operatorId);
    // }
  }

  getTopPlayers() {
    this.getOperatorId();
    this.gameService.getLeaderBoardTop(this.operatorId, this.user_id).subscribe(
      success => {
        console.log("Callback leaderboard data", success)
        this.leaderboardData = success['all'];
        var result = Object.keys(this.leaderboardData).map(e=>this.leaderboardData[e]);
        console.log(this.leaderboardData);
        console.log(result);
        this.leaderboardData = result;
        console.log("DATA LEADER", this.leaderboardData);
        // console.log("DATA LEADER one", this.leaderboardData[0]);
        // console.log("DATA LEADER", this.leaderboardData[0]['name']);

        this.CurrentUser = success['currentUser'];// false or currentuser
        if(!this.CurrentUser){
          this.showCurrentUser=true;
          console.log('this.showCurrentUser',this.showCurrentUser);
        }
        console.log("user_id", this.user_id);
        console.log("CurrentUser", this.CurrentUser);
      },
      error => {
        console.log("Error", error);
      });
  }
  /**
* Translate prizeData
*/
  translatePrizeData() {
    this.translateService.onLangChange.subscribe(language => {
      console.log("LANGUAGE CHANGED TO", language['lang']);
      this.checkLanguage(language['lang']);
    });
  }

  checkLanguage(lang) {
    if(this.recordsToOredoo['status']){
      if (lang === 'ar') {
        this.recordsToOredoo['name'] = this.leaderboardPrize['name'].ar;
        this.recordsToOredoo['description'] = this.leaderboardPrize['description'].ar;
      }
      else if (lang === 'fr') {

        this.recordsToOredoo['name'] = this.leaderboardPrize['name'].fr;
        this.recordsToOredoo['description'] = this.leaderboardPrize['description'].fr;
      }
      else {
        this.recordsToOredoo['name'] = this.leaderboardPrize['name'].en;
        this.recordsToOredoo['description'] = this.leaderboardPrize['description'].en;

      }
    }

  }

  checkUser(){
    this.user_id = this.userService.getUserId();
    if (this.user_id === undefined) {
      this.userService.getUserDetails().subscribe(
        user => {
          console.log("user" , user);
          this.user_id = user['user_id'];
          console.log('user_id----', this.user_id);
          this.getTopPlayers();
        },
        error => {
          console.log("error");
        },
        () => {
          console.log("checkuser4");
        }
      );
    } else {
      this.getTopPlayers();
    }


  }

  getCountry() {
    if(window.location.hostname == 'play.mtnsyr.com'){ // syria case
        this.countryCode = 'SY';
    }else{
      this.countryService.getCountryDetails().subscribe(country => {
        this.countryCode = country['countryCode'];
      });
    }
    
  }

}
