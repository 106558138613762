<div class="gslider">
  <div class="sliderInner">
    <div class="games">

      <div class="game" *ngFor="let game of sliderGames" routerLink="/game/{{ game.game_type }}/{{ game.game_id }}"  (click)="checkLogin($event,game)">
        <div class="img">
          <img src="{{ game.game_thumb }}" />
        </div>
        <div class="name">
          <div>
            {{ game.game_name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
