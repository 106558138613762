import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GamesService } from '../services/games/games.service';

@Component({
  selector: 'app-list-games',
  templateUrl: './list-games.component.html',
  styleUrls: ['./list-games.component.scss']
})
export class ListGamesComponent implements OnInit {

  gamesCategories: Array<object>;
  game_type: any = '';
  isMtn: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gamesService: GamesService,
    private spinnerService: NgxSpinnerService) {}

  ngOnInit() {
    this.setCurrentTab();

    this.getGamesType();
    const hostName = window.location.hostname;
    this.isMtn = hostName === 'gamesstore.mtn.com.ye' || hostName === 'play.mtnsyr.com' ? true : false;
  }
  setCurrentTab() {
    const pathname = window.location.pathname;
    if (pathname.endsWith('online')) {
      console.log('gameType', 'easy');
      localStorage.setItem('game_type', 'easy');
      console.log('game_type', 'easy');
      return localStorage.setItem('gameType', 'easy');
    }
    if (pathname.endsWith('android')) {
      if(window.location.hostname == 'ksa2.playit.mobi'){
        localStorage.setItem('game_type', 'easy');
        localStorage.setItem('gameType', 'easy');
        window.location.href = '/home/games/online';
      }else{
        console.log('gameType', 'andriod');
        localStorage.setItem('game_type', 'andriod');
        console.log('game_type', 'andriod');
        return localStorage.setItem('gameType', 'andriod');
  
      }
    }
    if(window.location.hostname == 'ksa2.playit.mobi'){
      localStorage.setItem('game_type', 'easy');
      localStorage.setItem('gameType', 'easy');
      window.location.href = '/home/games/online';
    }else{
       console.log('elsegameType', 'andriod');
      localStorage.setItem('game_type', 'andriod');
      console.log('game_type', 'andriod');
      return localStorage.setItem('gameType', 'andriod');
    }
   
  }
  /**
   * Determines type of game from data on routed to
   */
  getGamesType() {
    this.activatedRoute.data.subscribe(data => {
      this.game_type = data['gameType'];
      this.spinnerService.show();
      switch (data['gameType']) {
        case 'all':
          if(window.location.hostname == 'ksa2.playit.mobi'){
            this.getAllGamesWithType('easy');
          }else{
            this.getAllGames();
          }
          break;
        default:
          if(window.location.hostname == 'ksa2.playit.mobi'){
            this.getAllGamesWithType('easy');
          }else{
            this.getAllGamesWithType(data['gameType']);
          }
          break;
      }
    });
  }
  /**
   * Gets all games with type either android or online
   * @param gameType
   */
  getAllGamesWithType(gameType) {
    this.gamesService.getAllGamesWithType(gameType, 2).subscribe(games => {
      this.gamesCategories = games['game_list'];
      this.spinnerService.hide();
    });
  }
  /**
   * Gets all games with all types
   */
  getAllGames() {
    this.gamesService.getAllGames(2).subscribe(
      games => {
        this.gamesCategories = games['game_list'];
        this.spinnerService.hide();
      }
    );
  }

  goCategory(cat_name) {
    if (this.game_type === 'andriod') {
      this.router.navigateByUrl('/category/android/' + cat_name);
    } else if (this.game_type === 'easy') {
      this.router.navigateByUrl('/category/online/' + cat_name);
    } else if (this.game_type === 'all') {
      this.router.navigateByUrl('/category/lobby/' + cat_name);
    }
  }
}
