/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-sms.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./payment-sms.component";
var styles_PaymentSmsComponent = [i0.styles];
var RenderType_PaymentSmsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentSmsComponent, data: {} });
export { RenderType_PaymentSmsComponent as RenderType_PaymentSmsComponent };
export function View_PaymentSmsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["src", "/assets/images/pay/pay4.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "telecommunication"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["height", "75"], ["src", "/assets/images/pay/vodafone.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "img", [["height", "75"], ["src", "/assets/images/pay/etisalat.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["height", "75"], ["src", "/assets/images/pay/orange.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "a", [["href", "sms:2210?body=1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("PAY SMS")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("paySMS")); _ck(_v, 14, 0, currVal_1); }); }
export function View_PaymentSmsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-sms", [], null, null, null, View_PaymentSmsComponent_0, RenderType_PaymentSmsComponent)), i1.ɵdid(1, 114688, null, 0, i3.PaymentSmsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentSmsComponentNgFactory = i1.ɵccf("app-payment-sms", i3.PaymentSmsComponent, View_PaymentSmsComponent_Host_0, {}, {}, []);
export { PaymentSmsComponentNgFactory as PaymentSmsComponentNgFactory };
