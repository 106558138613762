import { Component, OnInit, ApplicationInitStatus } from '@angular/core';
import { CountryService } from '../../shared/services/country/country.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './../../shared/services/user/user.service';
@Component({
  selector: 'app-sy-subscribe',
  templateUrl: './sy-subscribe.component.html',
  styleUrls: ['./sy-subscribe.component.scss']
})
export class SySubscribeComponent implements OnInit {

  msisdn = '';
  password = '';
  name = '';
  email = '';
  error: string;
  success = false;
  countryCode: string;
  plan = 'd';
  url: string;
  constructor(
    private countryService: CountryService,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService
  ) {
console.log("here");
  }

  ngOnInit() {
    this.getCountry();
    //  this.translateService.setDefaultLang('en');
  }

  /*
  ** subscripe api
  ** parameters: msisdn & password & name & email
  */
 sySubscribe() {
  if (this.msisdn && this.plan) {
    this.countryService.sySubscribe(this.msisdn, this.plan).subscribe((res) => {
      if (res['userId'] && res['status'] ) {
        this.success = true;
        this.error = '';
        localStorage.setItem('userId', res['userId']);
        window.location.href = '/verify-subscribtion';
      } else {
        this.success = false;
        this.error = 'phone number, password and email are required';
      }
    }, (err) => {
      this.error = 'enter valid data!';
    });
  } else {
    this.error = 'phone number, password and email are required';
  }
}
  getCountry() {
    this.countryCode = 'SY';
    if (this.countryCode === 'YE' || this.countryCode === 'EG' || this.countryCode === 'SY') {
      this.translateService.setDefaultLang('ar');
    } else {
      this.translateService.setDefaultLang('en');
    }

    // this.countryService.getCountryDetails().subscribe(country => {
    //   this.countryCode = country['countryCode'];
    //   console.log(this.countryCode);
    //   if (this.countryCode === 'YE' || this.countryCode === 'EG' || this.countryCode === 'SY') {
    //     this.translateService.setDefaultLang('ar');
    //   } else {
    //     this.translateService.setDefaultLang('en');
    //   }
    // }
    // );

  }

  subscribe2() {
    const link = document.getElementById('sms');
    if (this.plan === '1') {
      this.url = 'sms:2593?body=%201';
      window.location.href = this.url;
    } else if (this.plan === '2') {
      this.url = 'sms:2593?body=%202';
      window.location.href = this.url;
    }

  }

}
