<div class="subscribe">
  <!-- <h2>{{'SUBSCRIBE' | translate}}</h2> -->
  <p class="error" *ngIf="error">{{'SUBSCRIBE_ERROR' | translate}}</p>
  <!-- <p class="success" *ngIf="success"><strong>{{name}}</strong>,  :)</p> -->

  <div *ngIf="!success" class="items">
    <div class="item">
      <input type="text" [(ngModel)]="msisdn" placeholder="{{'TYPE PHONE' | translate}} *"  autocomplete="off" />
    </div>
    <div class="item">
            <div class="dropdown-select">
              <select name="Subscription plan" id="subscription-plan" [(ngModel)]="plan" required >
                <option value="d" [selected]="true"><span>{{ 'DAILY_SY' | translate }} </span></option>
                <option value="w"><span>{{ 'WEEKLY_SY' | translate }}</span></option>
                <option value="m"><span>{{ 'MONTHLY_SY' | translate }}</span></option>
              </select>
            </div>
    </div>
    <div class="item">
        <div class="subscribe-btn">
            <button (click)="sySubscribe()">{{'SIGN UP' | translate}}</button>
        </div>
      </div>
  </div>
  
  <div class="terms">
      <span class="accept-subscribe"> {{'ACCEPT SUBSCRIBE' | translate}} </span> 
      <span class="terms-conditions"> 
          <a >{{'TERMS AND CONDITIONS' | translate}}</a>
      </span>
  </div>

</div>
