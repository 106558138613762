import { ConfigService } from '../config/config.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "@angular/common/http";
var CountryService = /** @class */ (function () {
    function CountryService(commonService, http, configService) {
        this.commonService = commonService;
        this.http = http;
        this.configService = configService;
        this.countryDetails = new Subject();
        this.hostName = this.commonService.getHostName();
        this.hostNamev2 = this.commonService.getHostv2();
    }
    CountryService.prototype.resolve = function () {
        this.initiateCountry();
    };
    /**
     * Returns observable to subscriber
     */
    CountryService.prototype.getCountryDetails = function () {
        return this.countryDetails.asObservable();
    };
    /**
     * Subscribes to API that gets country details
     */
    CountryService.prototype.initiateCountry = function () {
        return this.http.get(this.hostName + "getcountry");
    };
    /**
     * Adds country name and country code to stream of country details observable
     * @param countryName
     * @param countryCode
     */
    CountryService.prototype.setCountryDetails = function (countryName, countryCode) {
        var countryInfo = { "countryName": countryName, "countryCode": countryCode };
        this.countryDetails.next(countryInfo);
        this.countryDetails.complete();
    };
    /* ------------------- start Em_Developer ------------------- */
    // Subscribes to API that gets country details
    CountryService.prototype.initSubscribe = function (msisdn, password, name, email) {
        return this.http.get(this.hostNamev2 + "playit77/subscribe?msisdn=" + msisdn + "&password=" + password + "&name=" + name + "&email=" + email);
    };
    CountryService.prototype.sySubscribe = function (msisdn, plan) {
        return this.http.get("http://play.mtnsyr.com/syapi/api/v2/mtnsyria/subscribe?msisdn=" + msisdn + "&plan=" + plan);
    };
    CountryService.prototype.fawrySubscribe = function (data) {
        return this.http.post(this.hostNamev2 + "payfawry", data);
    };
    CountryService.ngInjectableDef = i0.defineInjectable({ factory: function CountryService_Factory() { return new CountryService(i0.inject(i1.ConfigService), i0.inject(i2.HttpClient), i0.inject(i1.ConfigService)); }, token: CountryService, providedIn: "root" });
    return CountryService;
}());
export { CountryService };
