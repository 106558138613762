<div class="body">
    <div class="header">
      <h3>{{'PAY VOUCHER' | translate}}</h3>
      <img src="/assets/images/pay/pay6.png" />
    </div>
    <div class="content">

        <div>
          <label>{{'PAY SCRATCH VOUCHER' | translate}}</label>
        </div>
        <div class="success" [hidden]="!showSuccess">
          {{'CORRECT VOUCHER ID' | translate}}
        </div>
        <div class="warning" [hidden]="!showWarning">
          {{'WRONG VOUCHER ID' | translate}}
        </div>
        <div>
          <input type="text" [(ngModel)]="voucher" placeholder="{{'PAY SCRATCH VOUCHER' | translate}}"/>
        </div>
        <input dir="auto" type="submit" value="{{'PAY VOUCHER' | translate}}" (click)="checkUser()"/>
    </div>
  </div>
