<!-- <div
  class="profile-header"
  [ngClass]="dirtest ? 'rtl' : 'nonrtl'"
  id="navigation"
>
  <div class="avatar">
    <img src="{{ userDetails?.user_photo }}" alt="" />
  </div>
  <div class="name">
    {{ userDetails?.user_name }}
  </div>
  <div class="profile-footer">
    <ng-container *ngIf="isEditable; else viewing">
      <div class="user-name">
          <label for="name">{{ "NAME" | translate }}</label>
          <input
            placeholder="{{ 'NAME' | translate }}"
            class="inputs"
            type="text"
            name="name"
            id="name"
            autocomplete="off"
            [(ngModel)]="userName"
            required
          />
      </div>
      <div class="user-email">
          <label for="email">{{ "EMAIL" | translate }}</label>
          <input
            placeholder="{{ 'EMAIL' | translate }}"
            class="inputs"
            type="text"
            id="email"
            name="email"
            autocomplete="off"
            [(ngModel)]="userEmail"
            required
          />
      </div>
      <div class="edit-buttons">
        <button type="submit" (click)="updateProfile()">
          {{ "UPDATE" | translate }}
        </button>
        <button type="submit" (click)="cancelUpdate()">
          {{ "CANCEL" | translate }}
        </button>
      </div>
    </ng-container>
    <ng-template #viewing>
      <div>
        <label for="name">{{ "NAME" | translate }}</label>
        <p>{{ userDetails?.user_name }}</p>
      </div>
      <div>
        <label for="name">{{ "EMAIL" | translate }}</label>
        <p>{{userEmail}}</p>
      </div>
      <button type="submit" (click)="editProfile()">
        {{ "EDIT" | translate }}
      </button>
    </ng-template>
  </div>
  

  <div class="user-name-container">
      <div class="row">
          <div class="field-edit-name">
            {{'REMIND TIME' | translate}}
          </div>
          <div class="remind-time" >
            {{days}}{{'DAY' | translate}} - {{hours}}{{'HOUR' | translate}}
          </div>
        </div>
        <div class="col-xs-6" >

        </div>
  </div>
</div> -->

<!-- 
<div class="row">
   <div class="small-12 medium-2 large-2 columns">
     <div class="circle">
       
       <img class="profile-pic" src="http://cdn.cutestpaw.com/wp-content/uploads/2012/07/l-Wittle-puppy-yawning.jpg">

     
     </div>
     <div class="p-image">
       <i class="fa fa-camera upload-button"></i>
        <input class="file-upload" type="file" accept="image/*"/>
     </div>
  </div>
</div> -->

<ng-container *ngIf="isEditable; else viewing">
 <div class="profile-container">
  <div class="profile-header" style="padding: 5%;"
    [ngClass]="dirtest ? 'rtl' : 'nonrtl'"
    id="navigation"
  > 
  
    <div class="update" (click)="updateProfile()">
      <img src="../../../assets/images/profile-icons/update.svg" >
    </div>
    <div class="row">
      <div class="small-12 medium-2 large-2 columns" style="position:relative">
        <div class="circle">
          <!-- User Profile Image -->
          <img class="profile-pic" src="{{ image || this.userDetails?.user_photo }}">
          <!-- <img class="profile-pic" src="{{ image}}"> -->
  
          <!-- Default Image -->
          <!-- <i class="fa fa-user fa-5x"></i> -->
        </div>
        <ng-container *ngIf="isMtn; else default">
          <div class="p-image">
            <img src="../../../assets/images/profile-icons/mtnCamera.svg" class="upload-button" (click)="file.click()"/>
            <input class="file-upload" type="file" accept="image/*"  (change)="getImage($event)" #file/>
          </div>
        </ng-container>
        <ng-template #default>
          <div class="p-image">
            <img src="../../../assets/images/profile-icons/defaultCamera.svg" class="upload-button" (click)="file.click()"/>
            <input class="file-upload" type="file" accept="image/*"  (change)="getImage($event)" #file/>
          </div>
        </ng-template>
       
      </div>
    </div>
    <div class="profile-footer">
      <div class="user-name">
          <!-- <label for="name">{{ "NAME" | translate }}</label> -->
          <!-- <input
            placeholder="{{ 'NAME' | translate }}"
            class="inputs"
            type="text"
            name="name"
            id="name"
            autocomplete="off"
            [(ngModel)]="userName"
            required
          /> -->
          <input

          class="inputs"
          type="text"
          name="name"
          id="name"
          autocomplete="off"
          [(ngModel)]="name"
          required
        />
      </div>
      <div class="user-email">
          <input
            placeholder="{{ 'TYPE EMAIL' | translate }}"
            class="inputs"
            type="text"
            id="email"
            name="email"
            autocomplete="off"
            [(ngModel)]="userEmail"
            required
          />
      </div>
      <!-- <div class="edit-buttons">
        <button type="submit" (click)="updateProfile()">
          {{ "UPDATE" | translate }}
        </button>
        <button type="submit" (click)="cancelUpdate()">
          {{ "CANCEL" | translate }}
        </button>
      </div> -->
    </div>
  </div>
  <div class="dates-container">
      <div class="date-container">
        <div class="calendar-img">
            <img src="../../../assets/images/profile-icons/calendar.svg"/>
        </div>
        <div class="date-title">
          {{ "SUBSCRIPTION_DATE" | translate }}
        </div>
        <div class="date">
          12 Aug 2019
        </div>
        
      </div>
      <div class="date-container">
        <div class="calendar-img">
            <img src="../../../assets/images/profile-icons/calendar.svg"/>
        </div>
        <div class="date-title">
          {{ "RENEWAL_DATE" | translate }}
        </div>
        <div class="date">
            12 Sep 2019
        </div>
        
      </div>
      
    </div>
 </div>
</ng-container>
<ng-template #viewing>
  <div class="profile-container">
      <div class="profile-header" style="padding: 5%;"
      [ngClass]="dirtest ? 'rtl' : 'nonrtl'"
      id="navigation"
    >
    <div class="edit" (click)="editProfile()">
      <img src="../../../assets/images/profile-icons/edit.svg" >
    </div>
    <div class="row">
      <div class="small-12 medium-2 large-2 columns" style="position:relative">
        <div class="circle">
          <!-- User Profile Image -->
          <img class="profile-pic" src="{{ image || this.userDetails?.user_photo }}">
          <!-- <img class="profile-pic" src="{{ image  }}"> -->
        </div>
      </div>
    </div>
    <div class="name">
      {{ name || this.userDetails?.user_name}}
    </div>
    <div class="email">
      {{this.userDetails?.email}}
    </div>
    </div>
    <div class="dates-container">
      <div class="date-container">
        <div class="calendar-img">
            <img src="../../../assets/images/profile-icons/calendar.svg"/>
        </div>
        <div class="date-title">
          {{ "SUBSCRIPTION_DATE" | translate }}
        </div>
        <div class="date">
          {{this.userDetails?.subscriptionDate}}
        </div>
        
      </div>
      <div class="date-container">
        <div class="calendar-img">
            <img src="../../../assets/images/profile-icons/calendar.svg"/>
        </div>
        <div class="date-title">
          {{ "RENEWAL_DATE" | translate }}
        </div>
        <div class="date">
            {{this.userDetails?.renewalDate}}
        </div>
        
      </div>
      
    </div> 
  </div>
 
 
   
  
    <!-- <div class="profile-footer">
      <div>
        <label for="name">{{ "NAME" | translate }}</label>

     <p>{{  name || userDetails?.user_name }}</p>
      </div>
      <div>
        <button type="button" (click)="editProfile()">
          {{ "EDIT" | translate }}
        </button>
      </div>
    </div> -->
    <!-- <div class="unsubscribe">
        <button routerLink="/unsubscribe" (click)="unsubscribe()" >
            {{'Unsubscribe' | translate}}
        </button>
    </div> -->
    
  
   
</ng-template>











