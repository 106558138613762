import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.scss']
})
export class SelectCountryComponent implements OnInit {

  constructor(private translateService: TranslateService) { }

  @Output() public childEvent = new EventEmitter();

  ngOnInit() {
  }

  changeLanguage(lang) {
    this.translateService.setDefaultLang(lang);
    localStorage.setItem('language', lang);
    setTimeout(() => {
      window.location.reload();
  }, 1000);
  }
}
