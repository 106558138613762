import { UserService } from './../../shared/services/user/user.service';
import { Component, OnInit, ApplicationInitStatus } from '@angular/core';
import { CountryService } from '../../shared/services/country/country.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ye-subscribe',
  templateUrl: './ye-subscribe.component.html',
  styleUrls: ['./ye-subscribe.component.scss']
})
export class YeSubscribeComponent implements OnInit {
  msisdn: string = '';
  password: string = '';
  name: string = '';
  email: string = '';
  error: string;
  success: boolean = false;
  countryCode: string;
  plan: string = '1';
  url: string;


  constructor(
    private countryService: CountryService,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService
  ) {

  }

  ngOnInit() {
    this.getCountry();
    //  this.translateService.setDefaultLang('en');
  }

  /*
  ** subscripe api
  ** parameters: msisdn & password & name & email
  */
  initSubscribe() {
    if (this.msisdn && this.password && this.email) {
      this.countryService.initSubscribe(this.msisdn, this.password, this.name, this.email).subscribe((res) => {
        console.log(res);
        if (res['id']) {
          this.success = true;
          this.error = '';
          //localStorage.setItem('userId', res['id']);
          this.userService.getUserToken(res['id']);
          // this.router.navigateByUrl('home/games/lobby');
          window.location.href = '/home/games/online';

        } else {
          this.success = false;
          this.error = 'phone number, password and email are required';
        }
      }, (err) => {
        this.error = 'enter valid data!';
      });
    } else {
      this.error = 'phone number, password and email are required';
    }
  }

  getCountry() {
    this.countryService.getCountryDetails().subscribe(country => {
      this.countryCode = country['countryCode'];
      console.log(this.countryCode);
      if (this.countryCode == "YE" || this.countryCode == "EG") {
        this.translateService.setDefaultLang('ar');
      }
      else {
        this.translateService.setDefaultLang('en');
      }
    }
    );

  }

  subscribe2() {
    var link = document.getElementById("sms");
    if (this.plan == "1") {
      this.url = "sms:2593?body=%201";
      window.location.href = this.url;
    }
    else if (this.plan == "2") {
      this.url = "sms:2593?body=%202";
      window.location.href = this.url;
    }

  }

}
