<div class="login-container" >
  <!-- <div class="back">
      <i class="fas fa-chevron-left"></i>
   </div> -->
  <div class="login-card">
        <div id="sign-in-form">
            <!-- <div class="logo-image-container">
              <img class="logo-img" src="/../../assets/playit.png"/>
            </div> -->
            <!-- verification code  -->
            <div id="wrapper">
              <div id="dialog">
                 
                <div class="send-verification">
                    {{'VERFICATION_CODE' | translate}}<br/>
                  
                  
              </div>
                <div id="form">
                  <input type="text" maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" [(ngModel)]="pinCode1"   (keyup)="keytab($event)"/>
                  <input type="text" maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" [(ngModel)]="pinCode2"   (keyup)="keytab($event)"/>
                  <input type="text" maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" [(ngModel)]="pinCode3"   (keyup)="keytab($event)"/>
                  <input type="text" maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" [(ngModel)]="pinCode4" (keyup)="keytab($event)"/>
                  <!-- <button class="btn btn-primary btn-embossed">Verify</button> -->
                </div>
                <div class="error-subscription" [hidden]="!isError">
                    {{'INCORRECT_CODE' | translate}}
                </div>
                
                <!-- <div>
                  Didn't receive the code?<br />
                  <a href="#">Send code again</a><br />
                  <a href="#">Change phone number</a>
                </div>
                 <img src="http://jira.moovooz.com/secure/attachment/10424/VmVyaWZpY2F0aW9uLnN2Zw==" alt="test" /> -->
              </div>
            </div>
            <!-- <div class="group">
                <input type="text" required>
                <span class="bar"></span>
                <label><span class="span-input">Enter Code Here</span></label>
            </div> -->
            <div class="resend-code-container">
                <!-- <div><i class="fas fa-redo"></i></div> -->
                <div class="resend-code">{{ 'DIDNOT_RECIEVE_CODE' | translate }}</div>
                <div class="resend-code-link" (click)="resendCode()">{{ 'RESEND_CODE' | translate }}</div>
            </div>
            <!--Finish-->
          
            <div class="btn-match-result-prediction" (click)="finish()">
              <button type="button" class="btn btn-success login">{{ 'FINISH' | translate }}</button>
            </div>
            <div class="terms-and-conditions">
                {{ 'SIGNUP_AGREEMENT' | translate }}<span>{{" "}}{{ 'TERMS_CONDITIONS' | translate }}</span>
            </div>
          </div>
  </div>

</div>



