import { Location } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../shared/services/user/user.service";
import { CountryService } from "./../../shared/services/country/country.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {
  @Input() headerView = "";

  langInterval: any;
  arabicLang = false;
  @Output() public childEvent = new EventEmitter();
  oldLocation: string = "";
  interval: any;
  constructor(
    private translateService: TranslateService,
    private countryService: CountryService,
    private userService: UserService,
    private router: Router,
    private _location: Location
  ) { }

  isUserExist: Boolean;
  isBackDisabled: Boolean = true;
  noBack: Boolean = true;
  showSearch: Boolean = false;
  bgName: String = 'black';
  inputSearch: string;
  lang: String = 'en';
  successLogin: string;
  customM: Boolean = true;
  countryCode: string;
  ngOnInit() {
    this.getCountry();
    this.checkBackEnabled();
    this.getUserStatus();
    this.customMenu();
    this.getRandomBg();
    this.function();
  }
  getRandomBg() {
    const bgs = [
      '/assets/images/login/1.png',
      '/assets/images/login/2.png',
      '/assets/images/login/3.png',
    ];
    const bg = bgs[Math.floor(Math.random() * bgs.length)];
    this.bgName = bg;
  }
  function() {
    this.langInterval = setInterval(() => {
      if (localStorage.getItem("headerView") == "sharedHeader") {
        clearInterval(this.langInterval);
      } else {
        this.arabicLang = this.getDefaultLang();
      }
    }, 500);
  }
  /**
   * Subscribes to observable to get country details
   */
  getCountry() {
    if(window.location.hostname == 'play.mtnsyr.com'){ // syria case
      this.countryCode = 'SY';
    }else{
      this.countryService.getCountryDetails().subscribe(country => {
        this.countryCode = country['countryCode'];
      });
    }
    
  }
  /**
   * Subscribes to router events and changed value of isBackDisabled to true on certain events
   */
  checkBackEnabled() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (
          event.url === "/home/games/lobby" ||
          event.url === "/home/games/online" ||
          event.url === "/home/games/android" ||
          event.url === "/login" ||
          event.url === "/subscribe"
        ) {
          this.isBackDisabled = false;
          this.noBack = true;
        } else {
          this.isBackDisabled = true;
          this.noBack = false;
        }
      }
    });
  }
  /**
   * Subscribes to get userStatus observable to check if user is logged in
   */
  getUserStatus() {
    this.userService.getUserStatus().subscribe(user => {
      this.isUserExist = user["isUserExist"];
    });
  }

  backClicked() {
    this._location.back();
  }

  checkOnChangeRoute() {
    if (this.showSearch == true) {
      this.interval = setInterval(() => {
        if (this.oldLocation !== this.router.url) {
          this.showSearch = false;
          clearInterval(this.interval);
        }
      }, 1000);
    }
  }

  searchShow() {
    if (this.showSearch == false) {
      this.showSearch = true;
      this.oldLocation = this.router.url;
      this.checkOnChangeRoute();
    } else if (this.showSearch == true) {
      this.showSearch = false;
    }
  }

  searchNow() {
    if (this.inputSearch.length > 0) {
      this.router.navigateByUrl("search/" + this.inputSearch);
      this.showSearch = false;
      this.inputSearch = "";
    }
  }

  searchClose() {
    this.showSearch = false;
    this.inputSearch = "";
  }

  /*
   ** custom menu in login & subscribe pages
   */
  customMenu() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.url == "/login" || event.url == "/subscribe") {
          this.customM = false;
        } else {
          this.customM = true;
        }
      }
    });
  }
  getDefaultLang() {
    if (localStorage.getItem("language") == "ar") {
      return true;
    } else {
      return false;
    }
  }

  isAndroid() {
    localStorage.setItem('gameType', 'andriod');
    localStorage.setItem('game_type', 'andriod');
   }
}
