/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./category.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./category.component";
import * as i5 from "@angular/router";
import * as i6 from "../services/games/games.service";
import * as i7 from "ngx-spinner";
import * as i8 from "../../shared/services/user/user.service";
var styles_CategoryComponent = [i0.styles];
var RenderType_CategoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoryComponent, data: {} });
export { RenderType_CategoryComponent as RenderType_CategoryComponent };
function View_CategoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "game"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkLogin(_v.context.$implicit.game_type, _v.context.$implicit.game_id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.game_thumb; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.game_name; _ck(_v, 4, 0, currVal_1); }); }
export function View_CategoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "categoryPage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dark-balck-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "games"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoryComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.games; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.catName)); _ck(_v, 3, 0, currVal_0); }); }
export function View_CategoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-category", [], null, null, null, View_CategoryComponent_0, RenderType_CategoryComponent)), i1.ɵdid(1, 114688, null, 0, i4.CategoryComponent, [i5.ActivatedRoute, i6.GamesService, i7.NgxSpinnerService, i8.UserService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CategoryComponentNgFactory = i1.ɵccf("app-category", i4.CategoryComponent, View_CategoryComponent_Host_0, {}, {}, []);
export { CategoryComponentNgFactory as CategoryComponentNgFactory };
