import { UserService } from './../../shared/services/user/user.service';
import { Component, OnInit, ApplicationInitStatus } from '@angular/core';
import { CountryService } from '../../shared/services/country/country.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  msisdn:string= '';
  password:string = '';
  name:string= '';
  email:string= '';
  error:string;
  success:boolean = false;
  countryCode: string ='';
  plan:string = '1';
  url:string;
  game_id: string;
  game_type: string;
 

  constructor(
    private countryService: CountryService,
    private router: Router,
    private userService: UserService,
    private loadingSpinner: NgxSpinnerService,
  ){

  }

  ngOnInit() {
    this.getCountry();
  }
  isURLGame() {
    console.log('inside isURLGame');
    console.log(localStorage.getItem('game_id'));
    console.log(localStorage.getItem('game_type'));
    //debugger;

    if (localStorage.getItem('game_id') && localStorage.getItem('game_type')) {
      this.game_id = localStorage.getItem('game_id');
      this.game_type = localStorage.getItem('game_type');
      //this.router.navigate(['/game', this.game_type, this.game_id]);
      //debugger;
      localStorage.removeItem('urlGame');
      //debugger;

    } else {
     // window.location.href = '/home/games/android';

    }

  }

  /*
  ** subscripe api
  ** parameters: msisdn & password & name & email
  */
  initSubscribe() {
  
    if(this.msisdn && this.password && this.email) {
      this.countryService.initSubscribe(this.msisdn,this.password,this.name,this.email).subscribe((res) => {
        console.log(res);
        if (res['id']) {
          this.success = true;
          this.error = '';
          //localStorage.setItem('userId', res['id']);
          this.userService.getUserToken(res['id']);
         // this.router.navigateByUrl('home/games/lobby');
        // window.location.href = '/home/games/android';
        this.isURLGame();

        } else {
          this.success = false;
          this.error = 'phone number, password and email are required';
        }
      }, (err) => {
        this.error = 'enter valid data!';
      });
    } else {
      this.error = 'phone number, password and email are required';
    }
  }

  subscribe2(){
    var link = document.getElementById('sms');

    console.log("plan",this.plan);

    if (this.plan === '1'){
      this.url = 'sms:1133?body=%201';
      window.location.href = this.url;
    } else if (this.plan === '2'){
      this.url = 'sms:1133?body=%202';
      window.location.href = this.url;
    }

  }

  getCountry() {
    if (window.location.hostname === 'play.mtnsyr.com'){ // syria case
      this.countryCode = 'SY';
    } else if (window.location.hostname === 'gamesstore.mtn.com.ye'){ // syria case
      this.countryCode = 'YE';
    } else {
      this.loadingSpinner.show();
      this.countryService.getCountryDetails().subscribe(country => {
        this.countryCode = country['countryCode'];
        this.loadingSpinner.hide();
      });
    }
    // this.countryCode = 'YE';
  }
}
