<ngx-aside #NgxAsidePanelLeft class="sidePanel" [showDefaultFooter]="false" [showDefaultHeader]="false"
    [position]="navDir">
    <header class="side-header">
        <div class="exit-nav" (click)="NgxAsidePanelLeft.hide()">
            <i class="fas fa-times"></i>
        </div>
        <div class="user-details">
            <div class="user-image">
                <img src="{{userDetails?.user_photo}}" alt="">
            </div>
        <div class="user-name">
            {{userDetails.user_name}}
        </div>
        </div>
    </header>
   <div class="side-footer">
        <div class="container" 
        [ngStyle]="{'background-image': 'url(' + userDetails?.user_photo + ')', 
        'background-repeat': 'no-repeat', 
        'background-size':'cover',
        'opacity': '1',
        'min-height': '100vh'}">
            <div class="menu-items">
                <div class="item">
                    <button routerLink="/user/profile" (click)="NgxAsidePanelLeft.hide()">
                        {{'MY PROFILE' | translate}}
                    </button>
                </div>
                <div class="item">
                    <button routerLink="/favourites" (click)="NgxAsidePanelLeft.hide()">
                        {{'MY FAVORITES' | translate}}
                    </button>
                </div>
                <div class="item">
                    <button routerLink="/faq" (click)="NgxAsidePanelLeft.hide()">
                        {{'FAQ' | translate}}
                    </button>
                </div>

                <div class="item">
                    <button routerLink="/home/games/leaderboard" (click)="NgxAsidePanelLeft.hide()" hidden="countryCode!=='YE'">
                        {{'LEADERBOARD' | translate}}
                    </button>
                </div>
                <div class="item">
                    <button routerLink="/home/games/leaderboard" (click)="NgxAsidePanelLeft.hide()" *ngIf="countryCode==='YE'">
                        {{'BEST_PLAYERS' | translate}}
                    </button>
                </div>
                <div class="item" [hidden]="countryCode === 'SY' || countryCode === 'YE'">
                    <button routerLink="/pay" (click)="NgxAsidePanelLeft.hide()" >
                        {{'Payment' | translate}}
                    </button>
                </div>
                <div class="item">
                    <button (click)="unsubscribe()" >
                        {{'UNSUBSCRIBE' | translate}}
                    </button>
               
                </div>
               
                <div class="item">
                        <button routerLink="/login" (click)="logout()">
                            {{'LOGOUT' | translate}}
                        </button>
                    </div>
            </div>
        </div>
    </div>
</ngx-aside>
<div class="menu-button">
    <button (click)="NgxAsidePanelLeft.show()">
        <i class="fas fa-bars"></i>
    </button>
</div>
