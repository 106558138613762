import { GuestService } from './../shared/services/guest/guest.service';
import { GameDetailsComponent } from './game-details/game-details.component';
import { PlayComponent } from './play/play.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListGamesComponent } from './list-games/list-games.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component'
const routes: Routes = [
  // {
  //   path: 'leaderboard',
  //   component: ListGamesComponent
  // },
  {
    path: 'leaderboard',
    component: LeaderboardComponent
    
  },
  {
    path: 'lobby',
    component: ListGamesComponent,
    data: { gameType: 'all' },
  },
  {
    path: 'online',
    component: ListGamesComponent,
    data: { gameType: 'easy' }
  },
  {
    path: 'android',
    component: ListGamesComponent,
    data: { gameType: 'andriod' }
  },
  {
    path: 'game/:gametype/:gameid',
    component: GameDetailsComponent,
    canActivate: [GuestService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GamesRoutingModule { }
