import { Component, Input,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './../../shared/services/user/user.service';

@Component({
  selector: 'app-games-tabs',
  templateUrl: './games-tabs.component.html',
  styleUrls: ['./games-tabs.component.scss']
})
export class GamesTabsComponent implements OnInit {
  games: any;
  feed: any;
  type: any;
  inAndroidTap = false;
  inOnlineTap = false;
  tabGameType: string;
  tabType: string;
  onlineClicked = false;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router

  ) { }

  ngOnInit() {
    
    if (localStorage.getItem('headerView') !== 'sharedHeader') {
      location.reload();
    }
    this.getTabGames();
    // if(localStorage.getItem('gameType') !== localStorage.getItem('game_type')){
    //   localStorage.getItem('gameType')
    // }
    console.log('gameType inside games-tabs',localStorage.getItem('gameType'));
    
    this.type = localStorage.getItem('gameType') ? localStorage.getItem('gameType') : 'andriod';
    console.log("type in route",this.type);
    this.inAndroidTap = this.route.params['_value'].type === 'andriod' ? true : false;
    this.inOnlineTap = this.route.params['_value'].type === 'easy' ? true : false;

  }
  isActiveRoute(routeName) {
    if (routeName === this.tabGameType) {
      return 'active-cat';
    }
  }
  isActiveMainTab(routeName) {
    if (routeName === this.tabType) {
      return 'active-tab';
    }
  }
  getTabGames() {
    this.route.params.subscribe(params => {
      //console.log(params);
      this.tabGameType = params['games_type'];
      this.tabType = params['type'];
      console.log('tabType', this.tabType);
      localStorage.setItem('game_type', params['type']);
      localStorage.setItem('gameType', params['type']);
      this.getFeed();
      this.getGames(params['games_type']);
      const allGames = this.getGames(params['games_type']);
      const filteredGames = allGames.filter(i => i.game_type === params['type']);
      this.games = filteredGames;
      //console.log(filteredGames);
      //debugger;
      //this.spinner.isLoading(false);
      // console.log('isLoading', this.isLoading);
    },error => {
      //this.spinner.isLoading(false);
    });
  }

  getFeed() {
    const feed = this.route.snapshot.data['categoriesTabs'];
    this.feed = feed;
  }

  getGames(paramType: any) {
    if (this.checkType(paramType)) {
      return this.feed[paramType];
    }
    return this.feed['top_game'];
  }
  checkType(type: any) {
    const types = Object.getOwnPropertyNames(this.feed);
    return types.includes(type) ? true : false;
  }
  checkLogin(gameType, gameId) {
    if (this.userService.getUserId()) {
      this.router.navigateByUrl('game/' + gameType + '/' + gameId);
    } else {
      this.router.navigateByUrl('login');
    }
  }

  isAndroid() {
    localStorage.setItem('gameType', 'andriod');
    localStorage.setItem('game_type', 'andriod');
  }
  isOnline() {
    localStorage.setItem('gameType', 'easy');
    localStorage.setItem('game_type', 'easy');
    this.onlineClicked= true;
  }

}
