/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-games.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../slider/slider.component.ngfactory";
import * as i5 from "../slider/slider.component";
import * as i6 from "@angular/router";
import * as i7 from "./list-games.component";
import * as i8 from "../services/games/games.service";
import * as i9 from "ngx-spinner";
var styles_ListGamesComponent = [i0.styles];
var RenderType_ListGamesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListGamesComponent, data: {} });
export { RenderType_ListGamesComponent as RenderType_ListGamesComponent };
function View_ListGamesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "icon"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "assets/images/category-icons/mtn/", _v.parent.context.$implicit.cat_name, ".svg"); _ck(_v, 1, 0, currVal_0); }); }
function View_ListGamesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "icon"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "assets/images/category-icons/default/", _v.parent.context.$implicit.cat_name, ".svg"); _ck(_v, 0, 0, currVal_0); }); }
function View_ListGamesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "games"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "category"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "icon-title-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListGamesComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["default", 2]], null, 0, null, View_ListGamesComponent_3)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "see-all"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goCategory(_v.context.$implicit.cat_name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-slider", [], null, null, null, i4.View_SliderComponent_0, i4.RenderType_SliderComponent)), i1.ɵdid(16, 638976, null, 0, i5.SliderComponent, [i6.Router], { sliderGames: [0, "sliderGames"], numberOfSlides: [1, "numberOfSlides"], autoPlay: [2, "autoPlay"], withGameDescription: [3, "withGameDescription"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMtn; var currVal_1 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.games_list; var currVal_5 = 4; var currVal_6 = false; var currVal_7 = true; _ck(_v, 16, 0, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_v.context.$implicit.cat_name)); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("SEE ALL")); _ck(_v, 12, 0, currVal_3); }); }
export function View_ListGamesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListGamesComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gamesCategories; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ListGamesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-games", [], null, null, null, View_ListGamesComponent_0, RenderType_ListGamesComponent)), i1.ɵdid(1, 114688, null, 0, i7.ListGamesComponent, [i6.Router, i6.ActivatedRoute, i8.GamesService, i9.NgxSpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListGamesComponentNgFactory = i1.ɵccf("app-list-games", i7.ListGamesComponent, View_ListGamesComponent_Host_0, {}, {}, []);
export { ListGamesComponentNgFactory as ListGamesComponentNgFactory };
