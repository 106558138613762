import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var GiftsService = /** @class */ (function () {
    function GiftsService(http, router) {
        this.http = http;
        this.router = router;
        this.baseURL = 'http://testing.playit.mobi/api/v2';
    }
    GiftsService.prototype.getLeaderboardPrize = function () {
        return this.http.get('http://testing.playit.mobi/api/v2/LBRewardsHandler/getRelatedReward?operatorId=QMGmvVF9Vb');
    };
    GiftsService.ngInjectableDef = i0.defineInjectable({ factory: function GiftsService_Factory() { return new GiftsService(i0.inject(i1.HttpClient), i0.inject(i2.Router)); }, token: GiftsService, providedIn: "root" });
    return GiftsService;
}());
export { GiftsService };
