import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from './../shared/shared.module';
import { FaqComponent } from './faq/faq.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { PaymentFawryComponent } from './pay/payment-fawry/payment-fawry.component';
import { PaymentHomeComponent } from './pay/payment-home/payment-home.component';
import { PaymentSmsComponent } from './pay/payment-sms/payment-sms.component';
import { PaymentVoucherComponent } from './pay/payment-voucher/payment-voucher.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserRoutingModule } from './user-routing.module';
import { YeSubscribeComponent } from './ye-subscribe/ye-subscribe.component';
import { SySubscribeComponent } from './sy-subscribe/sy-subscribe.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { TermsComponent } from './terms/terms.component';
import { VerifySubscribtionComponent } from './verify-subscribtion/verify-subscribtion.component';




@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    TranslateModule.forChild(),
    FormsModule,
    UserRoutingModule
  ],
  declarations: [
    SubscribeComponent,
    PaymentHomeComponent,
    PaymentFawryComponent,
    PaymentSmsComponent,
    PaymentVoucherComponent,
    FaqComponent,
    FavouritesComponent,
    UserProfileComponent,
    YeSubscribeComponent,
    SySubscribeComponent,
    UnsubscribeComponent,
    TermsComponent,
    VerifySubscribtionComponent,

  ],
  exports: [SubscribeComponent]
})
export class UserModule { }
