<div class="container">
  <div class="row dark-balck-background">
    <div class="tabs-container">
      <!-- <button
        (click)="isAndroid()"
        routerLink="/home/games/android"
        [ngClass]="isActiveMainTab('andriod')"

      >
        <i class="fas fa-download"></i>
        {{ "The Store" | translate }}
      </button> -->
      <button
        (click)="isOnline()"
        routerLink="/home/games/online"
        [ngClass]="isActiveMainTab('easy')"

      >
        <i class="fas fa-play"></i>
        {{ "Play Online" | translate }}
      </button>
    </div>
  </div>
  <div class="row cat-tabs-container">
    <div class="catsC">
      <div class="catTabs">
        <a
          class="tab"
          [routerLink]="['/games', type, 'top_game']"
          [ngClass]="isActiveRoute('top_game')"
        >
          <div>{{ "Top Games" | translate }}</div>
        </a>
        <a
          class="tab"
          [routerLink]="['/games', type, 'new_games']"
          [ngClass]="isActiveRoute('new_games')"
        >
          <div>{{ "New Games" | translate }}</div>
        </a>
        <a
          class="tab"
          [routerLink]="['/games', type, 'trend_games']"
          [ngClass]="isActiveRoute('trend_games')"
        >
          <div>{{ "Trending Games" | translate }}</div>
        </a>
        <a
          class="tab"
          [routerLink]="['/games', type, 'recommended']"
          [ngClass]="isActiveRoute('recommended')"
        >
          <div>{{ "Recommended" | translate }}</div>
        </a>
        <a
          class="tab"
          [routerLink]="['/games', type, 'games_under_25m']"
          [ngClass]="isActiveRoute('games_under_25m')"
          *ngIf="type !==  'easy'"
        >
          <div>{{ "Games Under 25MB" | translate }}</div>
        </a>
        <a
          class="tab"
          [routerLink]="['/games', type, 'subway']"
          [ngClass]="isActiveRoute('subway')"
          *ngIf="type !==  'easy'"
        >
          <div>{{ "Subway" | translate }}</div>
        </a>
        <a
          class="tab"
          [routerLink]="['/games', type, 'popular']"
          [ngClass]="isActiveRoute('popular')"
        >
          <div>{{ "Popular" | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="categoryPage">
  <div class="games">
    <div
      class="game"
      (click)="checkLogin(game.game_type, game.game_id)"
      *ngFor="let game of games"
    >
      <div class="img">
        <img [src]="game.game_thumb" alt="" />
      </div>
      <div class="name">
        {{ game.game_name }}
      </div>
    </div>
  </div>
</div>
