import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public isUserExist: boolean;
  public userId: string;
  public userData: any;
  public userDetails: Subject<object> = new Subject<object>();
  public userStatus: Subject<object> = new Subject<object>();
  public userToken: string;
  hostName: string;
  hostNamev2: string;
  testingHost: string;
  msisdn: string;
  //user_id:string;
  userName: string;
  public remind_time: string;
  hours: number;
  days: number;
  splitted: any;
  constructor(private cookieService: CookieService, private http: HttpClient, private configService: ConfigService,
     private router: Router) {
    this.hostName = this.configService.getHostName();
    this.hostNamev2 = this.configService.getHostv2();
    this.testingHost = this.configService.getTestingHostName();
  }
  /**
   * Returns user id
   */
  getUserId() {
    return this.userId;
  }
  /**
   * Returns user id
   */
  getMsisdn() {
    return this.msisdn;
  }
  getRemindTime() {
    return this.remind_time;
  }
  /**
   * Handles the user depending on cookie
   */
  handleUser() {
    switch (this.cookieService.check('userId')) {
      case true:

        this.getUserToken(this.cookieService.get('userId'));
        break;
      case false:
        // this.redirectUser(); // eman developer: home is by default page [1/2]
        break;
    }
  }
 
  /**
   * Calls API to get token for given user ID
   * @param userId
   */
  getUserToken(userId, redirect= false) {
    console.log('redirect', redirect);
    this.http.get(`${this.hostName}getToken/${userId}`).subscribe(user => {
      console.log(user);
      this.setUserData(user);
      if (redirect) {
        if (localStorage.getItem('game_id') && localStorage.getItem('game_type')) {
          //this.router.navigate(['/game', this.game_type, this.game_id]);
          localStorage.removeItem('urlGame');
          window.location.href = '/game/'+localStorage.getItem('game_type')+'/'+localStorage.getItem('game_id');
      
        } else {
          if(window.location.hostname == 'ksa2.playit.mobi'){
            window.location.href = '/home/games/online';
          }else{
            window.location.href = '/home/games/android';
          }
      
        }
       }
    },
      error => {
        this.userStatus.next({ "isUserExist": false });

        //redirect to login
      },()=>{

        if(redirect){
          if(window.location.hostname == 'ksa2.playit.mobi'){
            window.location.href = '/home/games/online';
          }else{
            window.location.href = '/home/games/android';
          }
        }
      })
     
  }

  /**
   * Sets the user data and pushes it into stream
   * @param data
   */
  setUserData(data) {
   // debugger;
    this.checkCookieSet(data['user_id']);
    if (data['status'] && data['user_id']!="") {
      this.userData = data;
      this.userId = data['user_id'];
      this.userToken = data['token'];
      this.msisdn = data['userName'];
      this.remind_time = data['remind_time'];
      this.userDetails.next(data);
      this.userStatus.next({ "isUserExist": true });
    }
    else {
      this.userStatus.next({ "isUserExist": false });
    }
  }
  unsetUserData(){
    this.userData = {};
    this.userId = '';
    this.userToken = '';
    this.msisdn='';
    this.remind_time = '';
    this.userDetails.next({});
    this.userStatus.next({ "isUserExist": false });
  }
  /**
   * Checks if the user cookie is stored and stores it
   * @param userId
   */
  checkCookieSet(userId) {
    switch (this.cookieService.check('userId')) {
      case true:
        break;
      case false:
        this.cookieService.set('userId', userId, undefined, '/');
        break;
    }
  }
  /**
   * Redirects the user to the login page if he is not already there
   */
  redirectUser() {
    if (this.router.url !== '/login') {
      this.router.navigate(['/login']);
    }
  }
  /**
   * Returns user details to observers as an observable
   */
  getUserDetails() {
    return this.userDetails.asObservable();
  }
  getUserData() {
    return this.userData;
  }
  /**
   * Returns user status to observers as an observable
   */
  getUserStatus() {
    return this.userStatus.asObservable();
  }
  getToken() {
    return this.userToken;
  }

  /**
   * Calls API to increment user points in leaderboard
   * @param userId
   */
  updateUserLBPoints(userId) {
    return this.http.get(`${this.testingHost}LeaderBoard/updateUserLBPoints?userId=${userId}`);
  }

  /**
   * unsubscribe
   */
  unsubscribe(id) {
    return this.http.get(`${this.hostNamev2}playit77/unsubscribe?userId=${id}`)
  }
  /**
   * add voutcher
   */
  addVoucher(msisdn, voucher) {
    return this.http.get(`${this.hostNamev2}playit77/addVoucher?msisdn=${msisdn}&voucher=${voucher}`);
  }

  logout(id) {
    return this.http.get(`${this.hostNamev2}playit77/logout?userId=${id}`);
  }

  getFavGames(userId: any) {
    return this.http.get(`${this.hostName}games/getmygames/${userId}`);
  }
  updateUser(data) {
    return this.http.post(`${this.hostName}updateUser`, data);
  }
  verifySubscribtion(data) {
    return this.http.post(`${this.hostNamev2}mtnsyria/verify`, data);
  }
  resendCode(userId) {
    return this.http.get(`${this.hostNamev2}mtnsyria/subscribe?userId=${userId}`);
}
unsubscribeKSA(msisdn){
  return this.http.get(`${this.hostNamev2}mobimind/sms/code?msisdn=${msisdn}`)
}
getUserTokenByMSIDN(msisdn, redirect = false) {
  console.log('getUserTokenByMSIDN');
  console.log(msisdn);

  this.http.get(`${this.hostName}getToken/${msisdn}/1`).subscribe(user => {
    console.log(user);
  console.log(user);
  this.setUserData(user);
  console.log('after set user');
}, error => {
    this.userStatus.next({ 'isUserExist': false });

    //redirect to login
  }, () => {

    if(window.location.hostname == 'ksa2.playit.mobi'){
      window.location.href = '/home/games/online';
    }else{
      window.location.href = '/home/games/android';
    }
  });
}
}