import { ConfigService } from '../../../shared/services/config/config.service';
import { HttpClient } from '@angular/common/http';
import { CountryService } from '../../../shared/services/country/country.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/services/config/config.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../shared/services/country/country.service";
var GamesService = /** @class */ (function () {
    function GamesService(configService, http, countryService) {
        var _this = this;
        this.configService = configService;
        this.http = http;
        this.countryService = countryService;
        this.baseURL = this.configService.getBaseURL();
        this.hostName = this.configService.getHostName();
        this.testingHost = this.configService.getTestingHostName();
        if (window.location.hostname == 'play.mtnsyr.com') { // syria case
            this.countryName = 'SY';
        }
        else {
            this.countryService.getCountryDetails().subscribe(function (country) {
                _this.countryName = country['countryName'];
            });
        }
    }
    /**
     * get games with type after pressing see all
     * @param type
     * @param order
     */
    GamesService.prototype.getAllGames_Type_android = function (type, order) {
        return this.http.get(this.hostName + "games/Androidcatgame/" + type + "/" + order + "/" + this.countryName);
    };
    /**
   * get games with type after pressing see all
   * @param type
   * @param order
   */
    GamesService.prototype.getAllGames_Type_online = function (type, order) {
        return this.http.get(this.hostName + "games/catgame/" + type + "/" + order + "/" + this.countryName);
    };
    GamesService.prototype.getAllGames_Type_all = function (type, order) {
        return this.http.get(this.hostName + "games/catgame/" + type + "/" + order + "/" + this.countryName + "/1");
    };
    /**
     * Calls API with order to get all games
     * @param order
     */
    GamesService.prototype.getAllGames = function (order) {
        return this.http.get(this.baseURL + "gameList/" + order + "/" + this.countryName);
    };
    /**
     * Calls API with gameType and order to get games with type android or online
     * @param type
     * @param order
     */
    GamesService.prototype.getAllGamesWithType = function (type, order) {
        return this.http.get(this.baseURL + "gameList/" + order + "/" + this.countryName + "/" + type);
    };
    /**
     * Calls API to get single game details
     * @param gameId
     * @param userId
     */
    GamesService.prototype.getGameWithId = function (gameId, userId) {
        return this.http.get(this.hostName + "games/gamedata/" + gameId + "/" + userId);
    };
    /**
     * Calls API to like game
     * @param gameId
     * @param userId
     */
    GamesService.prototype.likeGame = function (gameId, userId) {
        return this.http.get(this.hostName + "games/savegame/" + gameId + "/" + userId);
    };
    /**
     * Calls API to unlike game.
     */
    GamesService.prototype.unlikeGame = function (gameId, userId) {
        return this.http.get(this.hostName + "games/unsavegame/" + gameId + "/" + userId);
    };
    GamesService.prototype.downlaodGame = function (gameId) {
        return this.http.get(this.hostName + "games/downloadFile/" + gameId);
    };
    /*
    **get games list
    */
    GamesService.prototype.getLobbyGames = function (name_category) {
        /*
          http://www.api.playit.mobi/api/v1/
          http://staging.playit.mobi/api/v2/
          http://testing.playit.mobi/api/v2/
          http://www.api.playit.mobi/api/v2/playit77/
        */
        //return this.http.get(`http://www.api.playit.mobi/api/v2/playit77/games/getLobby/${id}`)
        return this.http.get(this.hostName + "games/catgame/" + name_category + "/2/egypt/1");
    };
    /**
     * getLeaderBoardTop
     * @param operatorId
     * @param userId
     */
    GamesService.prototype.getLeaderBoardTop = function (operatorId, userId) {
        if (operatorId === 'guest') {
            return this.http.get(this.testingHost + "LeaderBoard/getTopPlayers");
        }
        return this.http.get(this.testingHost + "LeaderBoard/getTopPlayers?operatorId=" + operatorId + "&userId=" + userId, { withCredentials: true });
    };
    GamesService.ngInjectableDef = i0.defineInjectable({ factory: function GamesService_Factory() { return new GamesService(i0.inject(i1.ConfigService), i0.inject(i2.HttpClient), i0.inject(i3.CountryService)); }, token: GamesService, providedIn: "root" });
    return GamesService;
}());
export { GamesService };
