import * as i0 from "@angular/core";
var ConfigService = /** @class */ (function () {
    function ConfigService() {
        this.hostName = 'http://www.api.playit.mobi/api/v1/';
        this.hostNamev2 = 'http://www.api.playit.mobi/api/v2/';
        this.baseURL = 'http://staging.playit.mobi/api/v2/';
        this.testingHost = "http://testing.playit.mobi/api/v2/";
        this.host = "http://testing.playit.mobi/api/playit/";
        if (window.location.hostname === 'play.mtnsyr.com') {
            this.hostName = 'http://play.mtnsyr.com/syapi/api/v1/';
            this.hostNamev2 = 'http://play.mtnsyr.com/syapi/api/v2/';
            this.baseURL = 'http://play.mtnsyr.com/sysapi/api/v2/';
            this.testingHost = "http://play.mtnsyr.com/sytapi/api/v2/";
            this.host = "http://play.mtnsyr.com/sytapi/api/playit/";
        }
    }
    ConfigService.prototype.getHostName = function () {
        return this.hostName;
    };
    ConfigService.prototype.getBaseURL = function () {
        return this.baseURL;
    };
    ConfigService.prototype.getTestingHostName = function () {
        return this.testingHost;
    };
    ConfigService.prototype.getHost = function () {
        return this.host;
    };
    ConfigService.prototype.getHostv2 = function () {
        return this.hostNamev2;
    };
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
