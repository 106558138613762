<div class="subscribe"  *ngIf="countryCode === 'EG'">
  <!-- <h2>{{'SUBSCRIBE' | translate}}</h2> -->
  <p class="error" *ngIf="error">{{'SUBSCRIBE_ERROR' | translate}}</p>
  <p class="success" *ngIf="success"><strong>{{name}}</strong>,  :)</p>

  <div *ngIf="!success" class="items">
    
      
    <div class="item">
     <!-- <i   class="fas fa-mobile-alt"></i> -->
      <input type="text" [(ngModel)]="msisdn" placeholder="{{'TYPE PHONE' | translate}} *" autocomplete="off" />
    </div>

    <div class="item">
        <!-- <i class="fas fa-unlock-alt  "></i> -->
      <input type="password" [(ngModel)]="password" placeholder="{{'TYPE PASSWORD' | translate}} *" autocomplete="off" />
    </div>

    <div class="item">
        <!-- <i class="fas fa-percent"></i> -->
      <input type="text" [(ngModel)]="name" placeholder="{{'TYPE NAME' | translate}} *" autocomplete="off" />
    </div>

    <div class="item">
      <input type="email" [(ngModel)]="email" placeholder="{{'TYPE EMAIL' | translate}} *" autocomplete="off" />
    </div>

    <div class="item">
      <button (click)="initSubscribe()">{{'SIGN UP' | translate}}</button>
    </div>
    <div  >
        <span class="accept-subscribe"> {{'ACCEPT SUBSCRIBE' | translate}} </span> 
        <span class="terms-conditions"> 
           <a >{{'TERMS AND CONDITIONS' | translate}}</a>
       </span>
       </div>
  </div>
</div>

<!-- <div class="subscribe"  *ngIf="countryCode === 'EG'">
<h2>{{'SUBSCRIBE' | translate}}</h2> 
  <p class="error" *ngIf="error">{{'SUBSCRIBE_ERROR' | translate}}</p>
  <p class="success" *ngIf="success"><strong>{{name}}</strong>,  :)</p>

  <div class="item">
    <div class="container">
      <div class="row">
        <div class="col-xs-8 col-md-6 col-md-offset-3 col-xs-offset-2">
          <div class="dropdown-select">
            <select name="Subscription plan" id="subscription-plan" [(ngModel)]="plan" required >
              <option value="1" [selected]="true"><span> {{ 'DAILY' | translate}}</span></option>
              <option value="2"><span>{{ 'WEEKLY' | translate }}</span></option>
            </select>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="item"></div>
  <div class="item"  style="text-align: center; margin-top: 20px;">
    <button (click)="subscribe2()">{{'SUBSCRIBE' | translate}}</button>
  </div>


  <div class="container">
    <div class="row">
      <div class="terms-and-conditions">
        <div class="container">
          <div class="row">
            <div class="terms-and-conditions-header">
              {{ 'TERMS AND CONDITIONS' | translate}}
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="terms-and-conditions-charges">
              {{ 'TERMS_AND_CONDITIONS_CHARGES' | translate}}
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="terms-and-conditions-unsubscribe">
              {{'TERMS_AND_CONDITIONS_UNSUBSCRIBE' | translate}}
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="terms-and-conditions-code">
              {{'TERMS_AND_CONDITIONS_CODE' | translate}}
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="terms-and-conditions-support">
              {{'TERMS_AND_CONDITIONS_SUPPORT' | translate}}
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="terms-and-conditions-charges">
              {{'DATA_CHARGES_APPLY' |translate}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div> -->



<div class="subscribe"  *ngIf="countryCode === 'YE'">
    <!-- <h2>{{'SUBSCRIBE' | translate}}</h2> -->
    <p class="error" *ngIf="error">{{'SUBSCRIBE_ERROR' | translate}}</p>
    <!-- <p class="success" *ngIf="success"><strong>{{name}}</strong>,  :)</p> -->
  
    <div *ngIf="!success" class="items">
      
        
      <div class="item">
       <i   class="fas fa-mobile-alt"></i>
        <input type="text" [(ngModel)]="msisdn" placeholder="{{'TYPE PHONE' | translate}} *" autocomplete="off" />
      </div>
  
      <div class="item">
          <i class="fas fa-unlock-alt  "></i>

        <input type="password" autocomplete="off" readonly 
        onfocus="this.removeAttribute('readonly');" [(ngModel)]="password" placeholder="{{'TYPE PASSWORD' | translate}} *" autocomplete="off" />
      </div>
  
      <div class="item">
          <i class="fas fa-percent"></i>
        <input type="text" placeholder="{{'REFFERAL CODE' | translate}} *" autocomplete="off" />
      </div>
  
     
  
      <div class="item">
        <button (click)="initSubscribe()">{{'SIGN UP' | translate}}</button>
      </div>
      <div  >
          <span class="accept-subscribe"> {{'ACCEPT SUBSCRIBE' | translate}} </span> 
          <span class="terms-conditions"> 
             <a >{{'TERMS AND CONDITIONS' | translate}}</a>
         </span>
         </div>
    </div>
  </div>