<!--<div class="body">-->
<!--  <div class="header">-->
<!--    <h3>{{'PAY FAWRY' | translate}}</h3>-->
<!--    <img src="/assets/images/pay/pay5.png" />-->
<!--  </div>-->
<!--  <div class="content">-->
<!--    <h3>FA RY'S API</h3>-->
<!--  </div>-->
<!--</div>-->
<div class="container">
<div class="subscribe" >
  <h2>{{'PAY FAWRY' | translate}}</h2>
  <p class="error" *ngIf="error">{{error_msg}}</p>
  <p class="your-order" *ngIf="success">
    {{ 'YOUR ORDER' | translate }} {{result['referenceNumber']}}
  </p>
  <p class="pending" *ngIf="success">
    {{ 'PENDING IN FAWRY' | translate }} {{ dt | date:'medium'  }}
  </p>

<!--  <p class="your-order" *ngIf="success">-->
<!--    {{ 'YOUR ORDER' | translate }} {{ "121354585" }}-->
<!--  </p>-->
<!--  <p class="pending" *ngIf="success">-->
<!--    {{ 'PENDING IN FAWRY' | translate }} {{ "20-5-2019" }}-->
<!--  </p>-->

  <div *ngIf="!success" class="items">
    <div class="item">
      <input type="text" [(ngModel)]="msisdn" placeholder="{{'TYPE PHONE' | translate}} *"  autocomplete="off" />
    </div>

   <!--  <div class="item">
      <input type="email" [(ngModel)]="email" placeholder="{{'TYPE EMAIL' | translate}} *" autocomplete="off" hidden/>
    </div> -->

    <div class="item">


            <div class="dropdown-select">
              <select name="Subscription plan" id="subscription-plan" [(ngModel)]="package" required >
                <option value="week" [selected]="true"><span>{{ 'WEEKLY' | translate }}  {{  "- 10" }} {{ 'EGP' | translate }}</span></option>
                <option value="month"><span>{{ 'MONTHLY' | translate }} {{  "- 30" }} {{ 'EGP' | translate }}</span></option>
                <option value="month3"><span>{{ 'MONTH3' | translate }} {{  "- 50" }} {{ 'EGP' | translate }}</span></option>
              </select>

            </div>

    </div>

    <div class="item">
      <button (click)="fawrySubscribe()">{{'PAY NOW' | translate}}</button>
    </div>
  </div>
</div>
</div>
