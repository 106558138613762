/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slider.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./slider.component";
var styles_SliderComponent = [i0.styles];
var RenderType_SliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SliderComponent, data: {} });
export { RenderType_SliderComponent as RenderType_SliderComponent };
function View_SliderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "game"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.checkLogin($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(2, "/game/", _v.context.$implicit.game_type, "/", _v.context.$implicit.game_id, ""); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.game_thumb, ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.game_name; _ck(_v, 6, 0, currVal_2); }); }
export function View_SliderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "gslider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "sliderInner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "games"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SliderComponent_1)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sliderGames; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_SliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-slider", [], null, null, null, View_SliderComponent_0, RenderType_SliderComponent)), i1.ɵdid(1, 638976, null, 0, i4.SliderComponent, [i2.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SliderComponentNgFactory = i1.ɵccf("app-slider", i4.SliderComponent, View_SliderComponent_Host_0, { sliderGames: "sliderGames", numberOfSlides: "numberOfSlides", autoPlay: "autoPlay", withGameDescription: "withGameDescription" }, {}, []);
export { SliderComponentNgFactory as SliderComponentNgFactory };
