/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./faq.component";
var styles_FaqComponent = [i0.styles];
var RenderType_FaqComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqComponent, data: {} });
export { RenderType_FaqComponent as RenderType_FaqComponent };
function View_FaqComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.context.$implicit)); _ck(_v, 1, 0, currVal_0); }); }
function View_FaqComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa  fa-question-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_3)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = _v.context.$implicit["TEXT"]; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_v.context.$implicit["TITLE"])); _ck(_v, 3, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_v.context.$implicit["SMALL_TEXT"])); _ck(_v, 8, 0, currVal_2); }); }
function View_FaqComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FaqComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("FAQ CONTENT PAGE")); _ck(_v, 2, 0, currVal_0); }, null); }
function View_FaqComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.context.$implicit)); _ck(_v, 1, 0, currVal_0); }); }
function View_FaqComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa  fa-question-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_6)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "small"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = _v.context.$implicit["TEXT"]; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_v.context.$implicit["TITLE"])); _ck(_v, 3, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_v.context.$implicit["SMALL_TEXT"])); _ck(_v, 8, 0, currVal_2); }); }
function View_FaqComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FaqComponent_5)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("FAQ CONTENT PAGE YE")); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FaqComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "faqPage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FAQ"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_4)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.countryCode !== "YE") && (_co.countryCode !== "SY")); _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.countryCode == "YE"); _ck(_v, 7, 0, currVal_1); }, null); }
export function View_FaqComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq", [], null, null, null, View_FaqComponent_0, RenderType_FaqComponent)), i1.ɵdid(1, 114688, null, 0, i4.FaqComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqComponentNgFactory = i1.ɵccf("app-faq", i4.FaqComponent, View_FaqComponent_Host_0, {}, {}, []);
export { FaqComponentNgFactory as FaqComponentNgFactory };
