import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { ConfigService } from '../config/config.service';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
import * as i2 from "@angular/common/http";
import * as i3 from "../config/config.service";
import * as i4 from "@angular/router";
var UserService = /** @class */ (function () {
    function UserService(cookieService, http, configService, router) {
        this.cookieService = cookieService;
        this.http = http;
        this.configService = configService;
        this.router = router;
        this.userDetails = new Subject();
        this.userStatus = new Subject();
        this.hostName = this.configService.getHostName();
        this.hostNamev2 = this.configService.getHostv2();
        this.testingHost = this.configService.getTestingHostName();
    }
    /**
     * Returns user id
     */
    UserService.prototype.getUserId = function () {
        return this.userId;
    };
    /**
     * Returns user id
     */
    UserService.prototype.getMsisdn = function () {
        return this.msisdn;
    };
    UserService.prototype.getRemindTime = function () {
        return this.remind_time;
    };
    /**
     * Handles the user depending on cookie
     */
    UserService.prototype.handleUser = function () {
        switch (this.cookieService.check('userId')) {
            case true:
                this.getUserToken(this.cookieService.get('userId'));
                break;
            case false:
                // this.redirectUser(); // eman developer: home is by default page [1/2]
                break;
        }
    };
    /**
     * Calls API to get token for given user ID
     * @param userId
     */
    UserService.prototype.getUserToken = function (userId, redirect) {
        var _this = this;
        if (redirect === void 0) { redirect = false; }
        console.log('redirect', redirect);
        this.http.get(this.hostName + "getToken/" + userId).subscribe(function (user) {
            console.log(user);
            _this.setUserData(user);
            if (redirect) {
                if (localStorage.getItem('game_id') && localStorage.getItem('game_type')) {
                    //this.router.navigate(['/game', this.game_type, this.game_id]);
                    localStorage.removeItem('urlGame');
                    window.location.href = '/game/' + localStorage.getItem('game_type') + '/' + localStorage.getItem('game_id');
                }
                else {
                    if (window.location.hostname == 'ksa2.playit.mobi') {
                        window.location.href = '/home/games/online';
                    }
                    else {
                        window.location.href = '/home/games/android';
                    }
                }
            }
        }, function (error) {
            _this.userStatus.next({ "isUserExist": false });
            //redirect to login
        }, function () {
            if (redirect) {
                if (window.location.hostname == 'ksa2.playit.mobi') {
                    window.location.href = '/home/games/online';
                }
                else {
                    window.location.href = '/home/games/android';
                }
            }
        });
    };
    /**
     * Sets the user data and pushes it into stream
     * @param data
     */
    UserService.prototype.setUserData = function (data) {
        // debugger;
        this.checkCookieSet(data['user_id']);
        if (data['status'] && data['user_id'] != "") {
            this.userData = data;
            this.userId = data['user_id'];
            this.userToken = data['token'];
            this.msisdn = data['userName'];
            this.remind_time = data['remind_time'];
            this.userDetails.next(data);
            this.userStatus.next({ "isUserExist": true });
        }
        else {
            this.userStatus.next({ "isUserExist": false });
        }
    };
    UserService.prototype.unsetUserData = function () {
        this.userData = {};
        this.userId = '';
        this.userToken = '';
        this.msisdn = '';
        this.remind_time = '';
        this.userDetails.next({});
        this.userStatus.next({ "isUserExist": false });
    };
    /**
     * Checks if the user cookie is stored and stores it
     * @param userId
     */
    UserService.prototype.checkCookieSet = function (userId) {
        switch (this.cookieService.check('userId')) {
            case true:
                break;
            case false:
                this.cookieService.set('userId', userId, undefined, '/');
                break;
        }
    };
    /**
     * Redirects the user to the login page if he is not already there
     */
    UserService.prototype.redirectUser = function () {
        if (this.router.url !== '/login') {
            this.router.navigate(['/login']);
        }
    };
    /**
     * Returns user details to observers as an observable
     */
    UserService.prototype.getUserDetails = function () {
        return this.userDetails.asObservable();
    };
    UserService.prototype.getUserData = function () {
        return this.userData;
    };
    /**
     * Returns user status to observers as an observable
     */
    UserService.prototype.getUserStatus = function () {
        return this.userStatus.asObservable();
    };
    UserService.prototype.getToken = function () {
        return this.userToken;
    };
    /**
     * Calls API to increment user points in leaderboard
     * @param userId
     */
    UserService.prototype.updateUserLBPoints = function (userId) {
        return this.http.get(this.testingHost + "LeaderBoard/updateUserLBPoints?userId=" + userId);
    };
    /**
     * unsubscribe
     */
    UserService.prototype.unsubscribe = function (id) {
        return this.http.get(this.hostNamev2 + "playit77/unsubscribe?userId=" + id);
    };
    /**
     * add voutcher
     */
    UserService.prototype.addVoucher = function (msisdn, voucher) {
        return this.http.get(this.hostNamev2 + "playit77/addVoucher?msisdn=" + msisdn + "&voucher=" + voucher);
    };
    UserService.prototype.logout = function (id) {
        return this.http.get(this.hostNamev2 + "playit77/logout?userId=" + id);
    };
    UserService.prototype.getFavGames = function (userId) {
        return this.http.get(this.hostName + "games/getmygames/" + userId);
    };
    UserService.prototype.updateUser = function (data) {
        return this.http.post(this.hostName + "updateUser", data);
    };
    UserService.prototype.verifySubscribtion = function (data) {
        return this.http.post(this.hostNamev2 + "mtnsyria/verify", data);
    };
    UserService.prototype.resendCode = function (userId) {
        return this.http.get(this.hostNamev2 + "mtnsyria/subscribe?userId=" + userId);
    };
    UserService.prototype.unsubscribeKSA = function (msisdn) {
        return this.http.get(this.hostNamev2 + "mobimind/sms/code?msisdn=" + msisdn);
    };
    UserService.prototype.getUserTokenByMSIDN = function (msisdn, redirect) {
        var _this = this;
        if (redirect === void 0) { redirect = false; }
        console.log('getUserTokenByMSIDN');
        console.log(msisdn);
        this.http.get(this.hostName + "getToken/" + msisdn + "/1").subscribe(function (user) {
            console.log(user);
            console.log(user);
            _this.setUserData(user);
            console.log('after set user');
        }, function (error) {
            _this.userStatus.next({ 'isUserExist': false });
            //redirect to login
        }, function () {
            if (window.location.hostname == 'ksa2.playit.mobi') {
                window.location.href = '/home/games/online';
            }
            else {
                window.location.href = '/home/games/android';
            }
        });
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.CookieService), i0.inject(i2.HttpClient), i0.inject(i3.ConfigService), i0.inject(i4.Router)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
