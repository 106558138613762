<div class="body">
  <div class="header">
    <h3>{{'PAY METHODS' | translate}}</h3>

  </div>

  <div class="contents">
    <a href="/pay/fawry" class="content">
        <img src="/assets/images/pay/pay1.png" width="80" />
        <h5>{{'PAY FAWRY' | translate}}</h5>

    </a>

    <a href="/pay/sms" class="content">
      <img src="/assets/images/pay/pay2.png" width="80" />
      <h5>{{'PAY SMS' | translate}}</h5>

    </a>

    <a href="/pay/voucher" class="content">
      <img src="/assets/images/pay/pay3.png" width="80" />
      <h5>{{'PAY VOUCHER' | translate}}</h5>
    </a>

    <a href="#" class="content">
      <img src="/assets/images/pay/pay4.png" width="80" />
      <h5>{{'PAY VOUCHER' | translate}}</h5>
    </a>

  </div>
</div>
