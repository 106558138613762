/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./favourites.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./favourites.component";
import * as i5 from "ngx-spinner";
import * as i6 from "ngx-cookie-service";
import * as i7 from "../../shared/services/user/user.service";
import * as i8 from "@angular/router";
var styles_FavouritesComponent = [i0.styles];
var RenderType_FavouritesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FavouritesComponent, data: {} });
export { RenderType_FavouritesComponent as RenderType_FavouritesComponent };
function View_FavouritesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "game"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkLogin(_v.context.$implicit.game_type, _v.context.$implicit.game_id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.game_thumb; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.game_name; _ck(_v, 4, 0, currVal_1); }); }
export function View_FavouritesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "categoryPage"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dark-balck-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "games"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FavouritesComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.games; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("MY FAVORITES")); _ck(_v, 3, 0, currVal_0); }); }
export function View_FavouritesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-favourites", [], null, null, null, View_FavouritesComponent_0, RenderType_FavouritesComponent)), i1.ɵdid(1, 114688, null, 0, i4.FavouritesComponent, [i5.NgxSpinnerService, i6.CookieService, i7.UserService, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FavouritesComponentNgFactory = i1.ɵccf("app-favourites", i4.FavouritesComponent, View_FavouritesComponent_Host_0, {}, {}, []);
export { FavouritesComponentNgFactory as FavouritesComponentNgFactory };
