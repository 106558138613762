import { UserProfileComponent } from './user/user-profile/user-profile.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CategoryComponent } from './games/category/category.component';
import { GameDetailsComponent } from './games/game-details/game-details.component';
import { GamesTabsComponent } from './games/games-tabs/games-tabs.component';
import { PlayComponent } from './games/play/play.component';
import { SearchComponent } from './layout/search/search.component';
import { CategoryTabsResolverService } from './shared/services/category-tabs-resolver/category-tabs-resolver.service';
import { CountryService } from './shared/services/country/country.service';
import { GuestService } from './shared/services/guest/guest.service';
import { FaqComponent } from './user/faq/faq.component';
import { FavouritesComponent } from './user/favourites/favourites.component';
import { PaymentFawryComponent } from './user/pay/payment-fawry/payment-fawry.component';
import { PaymentHomeComponent } from './user/pay/payment-home/payment-home.component';
import { PaymentSmsComponent } from './user/pay/payment-sms/payment-sms.component';
import { PaymentVoucherComponent } from './user/pay/payment-voucher/payment-voucher.component';
import { SubscribeComponent } from './user/subscribe/subscribe.component';
import { YeSubscribeComponent } from './user/ye-subscribe/ye-subscribe.component';
import { SySubscribeComponent } from './user/sy-subscribe/sy-subscribe.component';
import { VerifySubscribtionComponent } from './user/verify-subscribtion/verify-subscribtion.component';

var type = "online";
console.log(window.location.hostname);
if(window.location.hostname == 'ksa2.playit.mobi'){
  type = "online";
}else{
  type = "android";
}
const routes: Routes = [
  
  {
    path: '',
    redirectTo: 'home/games/'+type,
    pathMatch: 'full',
    resolve: {
      country: CountryService
    }
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomeModule',
    resolve: {
      categoriesTabs: CategoryTabsResolverService,
    }
  },
  {
    path: 'games/:type/:games_type',
    component: GamesTabsComponent,
    resolve: {
      categoriesTabs: CategoryTabsResolverService,
    }
  },
  {
    path: 'user',
    loadChildren: './user/user.module#UserModule'
  },
  {
    path: 'game/:gametype/:gameid',
    component: GameDetailsComponent,
    canActivate: [GuestService]
  },
  {
    path: 'play/:gametype/:gameid',
    component: PlayComponent
  },
  {
    path: 'pay',
    component: PaymentHomeComponent
  },
  {
    path: 'faq',
    component: FaqComponent
  },
  {
    path: 'pay/fawry',
    component: PaymentFawryComponent
  },
  {
    path: 'pay/sms',
    component: PaymentSmsComponent
  },
  {
    path: 'pay/voucher',
    component: PaymentVoucherComponent
  },
  {
    path: 'subscribe',
    component: SubscribeComponent
  },
  {
    path: 'subscribe/yemen',
    component: YeSubscribeComponent
  },
  {
    path: 'subscribe/syria',
    component: SySubscribeComponent
  },
  {
    path: 'verify-subscribtion',
    component: VerifySubscribtionComponent
  },
  {
    path: 'search/:inputSearch',
    component: SearchComponent
  },
  {
    path: 'category/:category_name',
    component: CategoryComponent,
    data: { gametype: 'all' }
  },
  {
    path: 'favourites',
    component: FavouritesComponent,
  },
  {
    path: 'category/:type/:category_name',
    component: CategoryComponent
  },
  {
    path: 'androidcategory/:category_name',
    component: CategoryComponent,
    data: { gametype: type }
  },
  {
    path: 'profile',
    component: UserProfileComponent,
  },
  {
    path: '**',
    redirectTo: 'home/games/'+type,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  declarations: []
})
export class AppRoutingModule { }
