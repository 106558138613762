import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable} from 'rxjs';
import { Resolve } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CountryService implements Resolve<any> {
  hostName: string;
  hostNamev2: string;
  countryName: string;
  countryCode: string;
  countryDetails: Subject<object> = new Subject<object>();
  constructor(
    private commonService: ConfigService,
    private http: HttpClient,
    private configService: ConfigService
  ) {
    this.hostName = this.commonService.getHostName();
    this.hostNamev2 = this.commonService.getHostv2();
  }
  resolve(){
    this.initiateCountry();
  }
  /**
   * Returns observable to subscriber
   */
  public getCountryDetails() {
    return this.countryDetails.asObservable();
  }
  /**
   * Subscribes to API that gets country details
   */
  initiateCountry() {
    return this.http.get(`${this.hostName}getcountry`);
  }

  /**
   * Adds country name and country code to stream of country details observable
   * @param countryName
   * @param countryCode
   */
  setCountryDetails(countryName, countryCode) {
    var countryInfo = { "countryName": countryName, "countryCode": countryCode };
    this.countryDetails.next(countryInfo);
    this.countryDetails.complete();
  }

  /* ------------------- start Em_Developer ------------------- */

  // Subscribes to API that gets country details
  initSubscribe(msisdn,password,name,email) {
    return this.http.get(`${this.hostNamev2}playit77/subscribe?msisdn=${msisdn}&password=${password}&name=${name}&email=${email}`);
  }
  sySubscribe(msisdn,plan) {
    return this.http.get(`http://play.mtnsyr.com/syapi/api/v2/mtnsyria/subscribe?msisdn=${msisdn}&plan=${plan}`);
  }
  fawrySubscribe(data) {
    return this.http.post(`${this.hostNamev2}payfawry`,data);
  }

  /* ------------------- end Em_Developer ------------------- */
}
