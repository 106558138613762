import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SlickModule } from 'ngx-slick';
import { TabsModule } from 'ngx-tabset';
import { SharedModule } from '../shared/shared.module';
import { CategoryComponent } from './category/category.component';
import { GameDetailsComponent } from './game-details/game-details.component';
import { GamesRoutingModule } from './games-routing.module';
import { GamesTabsComponent } from './games-tabs/games-tabs.component';
import { IframePipe } from './iframe.pipe';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { ListGamesComponent } from './list-games/list-games.component';
import { PlayComponent } from './play/play.component';
import { SliderComponent } from './slider/slider.component';
@NgModule({
  imports: [
    CommonModule,
    GamesRoutingModule,
    SharedModule,
    TabsModule,
    TranslateModule.forChild(),
    SlickModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,

  ],
  declarations: [ListGamesComponent,
    GameDetailsComponent, SliderComponent, PlayComponent,
    IframePipe, CategoryComponent, GamesTabsComponent, LeaderboardComponent, ],
  exports: [SliderComponent, TabsModule]
})
export class GamesModule { }
