import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../shared/services/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  baseURL: string;
  hostName: string;
  testingHost: string;
  countryName: string;
  demoHost: string;
  constructor(private http: HttpClient,private configService: ConfigService) { 
    this.baseURL = this.configService.getBaseURL();
    this.hostName = this.configService.getHostName();
    this.testingHost = this.configService.getTestingHostName();
  }

  // search
  searchGames(searchQuery) {
    if(window.location.hostname == 'ksa2.playit.mobi'){
      return this.http.post(`${this.hostName}searchGames`, {'search_query': searchQuery,"type":1});
    }else{
      return this.http.post(`${this.hostName}searchGames`, {'search_query': searchQuery});
    }
  }
}
