import { UserService } from './../../../shared/services/user/user.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../shared/services/config/config.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  hostName: string;
  host: string;
  hostv2: string;
  constructor(private http: HttpClient, private configService: ConfigService,
    private userService: UserService, private router: Router) {
    this.hostName = this.configService.getHostName();
    this.host = this.configService.getHost();
    this.hostv2 = this.configService.getHostv2();
  }
  canActivate() {
    if (this.userService.getUserId()) {
      this.router.navigate(['/home/games/online']);
      return false;
    } else {
      return true;
    }
  }
  /**
   * Sends form consisting of user name and user's password to login the user. 
   * @param userData 
   */
  loginUser(userData) {
    return this.http.post(`${this.hostName}login`, userData);
  }
  checkUserExist(username) {
    return this.http.get(`${this.hostName}checkusername/${username}`);
  }
  sendPasswordSMS(username) {
    return this.http.get(`${this.hostv2}mtnyemn/resetPassword?msisdn=${username}`);
  }

  logout() {
    //return this.http.get(`http://api.playit.mobi/api/v2/playit77/logout`); 
    //return this.http.get(`http://api.playit.mobi/api/v2/playit77/logout`); 
    return this.http.get(`${this.hostName}logout`);
  }
  /*send password sms to all portal except mtn*/

  sendPassword(data) {
    return this.http.post(`${this.host}forgetPassword}`,data);

  }
}
