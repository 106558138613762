<div class="gDetails-container">
  <div class="intro">
    <div class="img">
      <img src="{{gameThumb}}" alt="" />
    </div>
    <div class="info">
      <ng-container *ngIf="language === 'ar' && gameNameAr !== 'undefined'  && gameNameAr !== undefined && gameNameAr !== 'null' && gameNameAr !== null && gameNameAr !== '' ; else defaultName" >
      <h2>{{this.gameNameAr}}</h2>
      </ng-container>
      <ng-template #defaultName>
          <h2>{{this.gameName}}</h2>
      </ng-template>
      <!-- <h2>{{ gameDetailsTranslated['game_name'] }}</h2> -->
      <div class="dinfo">
        <div class="by">By {{gameVendor }}</div>
        <div class="genre">{{ gameCat }}</div>
        <div class="like">

            
          <div class="icon" [ngStyle]="{ 'color': favourite ? 'red' : 'white' }">
            <button (click)="toggleLike()">
              <i class="fas fa-heart"></i>
            </button>
          </div>
          <!-- <div class="icon" [ngStyle]="{ 'color': gameDetails['gameIsLiked'] ? 'red' : 'white' }">
            <button (click)="toggleLike()">
              <i class="fas fa-heart"></i>
            </button>
          </div> -->
        </div>
        <div class="game-size" *ngIf="gameType==='andriod'">{{ game_size }}MB</div>
      </div>
    </div>
  </div>
  <div class="details">
      <ng-container *ngIf="language === 'ar' && gameDescribeAr !== 'undefined' && gameDescribeAr !== undefined && gameDescribeAr !== 'null'  && gameDescribeAr !== null && gameDescribeAr !== ''  ; else defaultDescribe" >
          <p>{{this.gameDescribeAr}}</p>
          </ng-container>
          <ng-template #defaultDescribe>
              <p>{{ gameDescribe }}</p>
          </ng-template>
    
  </div>
  <!-- <div class="imgs">
    <img src="https://placehold.it/104x58" alt="" />
    <img src="https://placehold.it/104x58" alt="" />
    <img src="https://placehold.it/104x58" alt="" />
  </div>
  <div class="stats">
    <div class="likes">
      <div class="num">486</div>
      <div class="txt">Game Likes</div>
    </div>
    <div class="hours">
      <div class="num">4.21k</div>
      <div class="txt">Total Hours</div>
    </div>
    <div class="users">
      <div class="num">124k</div>
      <div class="txt">Total Users</div>
    </div>
  </div> -->
  <!-- <div class="howto">
    <div class="title">How To Play:</div>
    <ol>
      <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
      <li>Rerum atque illum doloremque animi voluptatem</li>
      <li>soluta eum repellendus ipsa ea nihil saepe natus</li>
      <li>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</li>
    </ol>
  </div> -->
  <div class="playnow">
    <div
      class="game-button"
      *ngIf="gameType"
      [hidden]="gameType === 'andriod'"
    >
      <div>
        <ng-container *ngIf="isMtn; else default">
          <img src="../../../assets/images/buttons/mtn/Download&PlayNow-Ltr.svg" alt="" />
          <!-- <img src="../../../assets/images/buttons/mtn/Download&PlayNow-Rtl.svg" alt="" /> -->
        </ng-container>
        <ng-template #default>
            <img src="../../../assets/images/buttons/default/Download&PlayNow-Ltr.svg" alt="" />
            <!-- <img src="../../../assets/images/buttons/mtn/Download&PlayNow-Rtl.svg" alt="" /> -->
        </ng-template>

        <div class="button-type"  (click)="playHTML()">
          {{ "PLAY" | translate }}
        </div>
      </div>
    </div>

    <div
      class="game-button"
      *ngIf="gameType === 'andriod' && gameFolder"
    >
      <div>
        <ng-container *ngIf="isMtn; else default">
          <img src="../../../assets/images/buttons/mtn/Download&PlayNow-Ltr.svg" alt="" />
          <!-- <img src="../../../assets/images/buttons/mtn/Download&PlayNow-Rtl.svg" alt="" /> -->
        </ng-container>
        <ng-template #default>
            <img src="../../../assets/images/buttons/default/Download&PlayNow-Ltr.svg" alt="" />
            <!-- <img src="../../../assets/images/buttons/mtn/Download&PlayNow-Rtl.svg" alt="" /> -->
        </ng-template>
        <div class="button-type">
          <a download href="{{ gameFolder }}">
            <span class="">{{ "DOWNLOAD" | translate }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
