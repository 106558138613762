import { HttpClient } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigService } from '../../../shared/services/config/config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-spinner";
import * as i3 from "../config/config.service";
var CategoryTabsResolverService = /** @class */ (function () {
    function CategoryTabsResolverService(http, spinnerService, configService) {
        this.http = http;
        this.spinnerService = spinnerService;
        this.configService = configService;
        this.hostName = this.configService.getHostName();
    }
    CategoryTabsResolverService.prototype.resolve = function () {
        var _this = this;
        this.spinnerService.show();
        if (window.location.hostname == 'ksa2.playit.mobi') {
            return this.http.get(this.hostName + "getGames?type=easy")
                .toPromise()
                .then(function (res) {
                _this.spinnerService.hide();
                return res["games_list"];
            });
        }
        else {
            return this.http.get(this.hostName + "getGames")
                .toPromise()
                .then(function (res) {
                _this.spinnerService.hide();
                return res["games_list"];
            });
        }
    };
    CategoryTabsResolverService.ngInjectableDef = i0.defineInjectable({ factory: function CategoryTabsResolverService_Factory() { return new CategoryTabsResolverService(i0.inject(i1.HttpClient), i0.inject(i2.NgxSpinnerService), i0.inject(i3.ConfigService)); }, token: CategoryTabsResolverService, providedIn: "root" });
    return CategoryTabsResolverService;
}());
export { CategoryTabsResolverService };
