import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GiftsService {

  baseURL = 'http://testing.playit.mobi/api/v2';


  constructor(private http: HttpClient, private router: Router) { }

  getLeaderboardPrize(){
    return this.http.get('http://testing.playit.mobi/api/v2/LBRewardsHandler/getRelatedReward?operatorId=QMGmvVF9Vb');
  }
}
