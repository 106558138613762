import { GuestService } from './../shared/services/guest/guest.service';
import { GameDetailsComponent } from './game-details/game-details.component';
import { Routes } from '@angular/router';
import { ListGamesComponent } from './list-games/list-games.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
var ɵ0 = { gameType: 'all' }, ɵ1 = { gameType: 'easy' }, ɵ2 = { gameType: 'andriod' };
var routes = [
    // {
    //   path: 'leaderboard',
    //   component: ListGamesComponent
    // },
    {
        path: 'leaderboard',
        component: LeaderboardComponent
    },
    {
        path: 'lobby',
        component: ListGamesComponent,
        data: ɵ0,
    },
    {
        path: 'online',
        component: ListGamesComponent,
        data: ɵ1
    },
    {
        path: 'android',
        component: ListGamesComponent,
        data: ɵ2
    },
    {
        path: 'game/:gametype/:gameid',
        component: GameDetailsComponent,
        canActivate: [GuestService]
    },
];
var GamesRoutingModule = /** @class */ (function () {
    function GamesRoutingModule() {
    }
    return GamesRoutingModule;
}());
export { GamesRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
