import { CookieService } from 'ngx-cookie-service';
import { UserService } from './../user/user.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../user/user.service";
import * as i2 from "@angular/router";
import * as i3 from "ngx-cookie-service";
var GuestService = /** @class */ (function () {
    function GuestService(userService, router, cookieService) {
        this.userService = userService;
        this.router = router;
        this.cookieService = cookieService;
    }
    GuestService.prototype.canActivate = function () {
        if (!this.userService.getUserId() && !this.cookieService.check('userId')) {
            this.router.navigate(['login']);
            return false;
        }
        else {
            return true;
        }
    };
    GuestService.ngInjectableDef = i0.defineInjectable({ factory: function GuestService_Factory() { return new GuestService(i0.inject(i1.UserService), i0.inject(i2.Router), i0.inject(i3.CookieService)); }, token: GuestService, providedIn: "root" });
    return GuestService;
}());
export { GuestService };
