import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS} from '@angular/common/http';
import { InterceptorService } from './services/interceptor/interceptor.service';
@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    {
      provide:HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    CookieService
  ]
})
export class SharedModule { }
