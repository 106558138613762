import { Injectable } from '@angular/core';
import { ConfigService } from '../../../shared/services/config/config.service';
import { HttpClient } from '@angular/common/http';
import { CountryService } from '../../../shared/services/country/country.service';

@Injectable({
  providedIn: 'root'
})
export class GamesService {
  baseURL: string;
  hostName: string;
  testingHost: string;
  countryName: string;
  demoHost: string;
  constructor(private configService: ConfigService, private http: HttpClient, private countryService: CountryService) {
    this.baseURL = this.configService.getBaseURL();
    this.hostName = this.configService.getHostName();
    this.testingHost = this.configService.getTestingHostName();
    if(window.location.hostname == 'play.mtnsyr.com'){ // syria case
      this.countryName = 'SY';
    }else{
      this.countryService.getCountryDetails().subscribe(
        country => {
          this.countryName = country['countryName'];
        }
      );
    }
    
  }

  
  /**
   * get games with type after pressing see all
   * @param type 
   * @param order 
   */
  getAllGames_Type_android(type,order){
    return this.http.get(`${this.hostName}games/Androidcatgame/${type}/${order}/${this.countryName}`);
  }
 

    /**
   * get games with type after pressing see all
   * @param type 
   * @param order 
   */
  getAllGames_Type_online(type,order){
    return this.http.get(`${this.hostName}games/catgame/${type}/${order}/${this.countryName}`);
  }
  getAllGames_Type_all(type,order){
    return this.http.get(`${this.hostName}games/catgame/${type}/${order}/${this.countryName}/1`);
  }





  /**
   * Calls API with order to get all games 
   * @param order 
   */
  getAllGames(order) {
    return this.http.get(`${this.baseURL}gameList/${order}/${this.countryName}`);
  }

  /**
   * Calls API with gameType and order to get games with type android or online
   * @param type 
   * @param order 
   */
  getAllGamesWithType(type, order) {
    return this.http.get(`${this.baseURL}gameList/${order}/${this.countryName}/${type}`);
  }

  /**
   * Calls API to get single game details
   * @param gameId 
   * @param userId 
   */
  getGameWithId(gameId, userId) {
    return this.http.get(`${this.hostName}games/gamedata/${gameId}/${userId}`)  ;
  }
  /**
   * Calls API to like game
   * @param gameId 
   * @param userId 
   */
  likeGame(gameId, userId){
    return this.http.get(`${this.hostName}games/savegame/${gameId}/${userId}`)  ;
  }
  /**
   * Calls API to unlike game. 
   */
  unlikeGame(gameId, userId){
    return this.http.get(`${this.hostName}games/unsavegame/${gameId}/${userId}`)  ;
  }
  downlaodGame(gameId){
    return this.http.get(`${this.hostName}games/downloadFile/${gameId}`);
  }

  /*
  **get games list 
  */
  getLobbyGames(name_category) {
    /*
      http://www.api.playit.mobi/api/v1/
      http://staging.playit.mobi/api/v2/
      http://testing.playit.mobi/api/v2/
      http://www.api.playit.mobi/api/v2/playit77/
    */
   //return this.http.get(`http://www.api.playit.mobi/api/v2/playit77/games/getLobby/${id}`)
   return this.http.get(`${this.hostName}games/catgame/${name_category}/2/egypt/1`)
  }
/**
 * getLeaderBoardTop
 * @param operatorId 
 * @param userId 
 */
  getLeaderBoardTop(operatorId,userId) {
    if (operatorId === 'guest') {
      return this.http.get(`${this.testingHost}LeaderBoard/getTopPlayers`);
    }
    return this.http.get(`${this.testingHost}LeaderBoard/getTopPlayers?operatorId=${operatorId}&userId=${userId}`, { withCredentials: true });
  }
}
